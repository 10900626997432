import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";
// import './assets/css/inonic.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./pages/Login";
import LeftNav from "./layout/LeftNav";
import TopNav from "./layout/TopNav";
import Error404 from "./global/Error404";
import Logout from "./pages/Logout";
import Dashboard from "./pages/Dashboard";
import UserPage from "./pages/UserPage";
import CredentialPage from "./pages/CredentialPage";
import DocumentPage from "./pages/DocumentPage";
import PaymentInPage from "./pages/PaymentInPage";
import CatoryPage from "./pages/CatoryPage";
import WithdrawPage from "./pages/WithdrawPage";
import JobsPage from "./pages/JobsPage";
import LicensePage from "./pages/LicensePage";
import QuizPage from "./pages/QuizPage";
import ClientPage from "./pages/ClientPage";
import StaffPage from "./pages/StaffPage";
import StaffFacilityPage from "./pages/StaffFacilityPage";
import FacilityPage from "./pages/FacilityPage";
import Notification from "./Notification";
import NotificationPage from "./pages/NotificationPage";
import CaPage from "./pages/CaPage";

class App extends Component {
  render() {
    // console.log(this.props);
    let login_layout = true;
    if (this.props.data.login_layout === "STEPHEN_WHOCODED") {
      login_layout = false;
    } else {
      login_layout = true;
    }

    // console.log(login_layout);
    return (
      <>
        <Notification />
        <div className={login_layout ? "st-bg-gray3 st-scroll" : ""}>
          {login_layout ? <LeftNav /> : ""}

          <div
            className={
              login_layout
                ? `content ht-100v ${this.props.data.mmm ? "stttttg" : ""} pd-0`
                : ""
            }
          >
            {login_layout ? <TopNav /> : ""}
            <div className={login_layout ? "container pd-x-0 p-0" : ""}>
              {login_layout ? <div className="st-empty"></div> : ""}
              <div className={login_layout ? "content-body" : ""}>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/users" exact component={UserPage} />
                  <Route path="/clients" exact component={ClientPage} />
                  <Route path="/staffs" exact component={StaffPage} />
                  <Route
                    path="/staff-facilities"
                    exact
                    component={StaffFacilityPage}
                  />
                  <Route path="/facilities" exact component={FacilityPage} />
                  <Route path="/credentials" exact component={CredentialPage} />
                  <Route path="/documents" exact component={DocumentPage} />
                  <Route path="/payment-info" exact component={PaymentInPage} />
                  <Route path="/job-category" exact component={CatoryPage} />
                  <Route path="/job-ca" exact component={CaPage} />
                  <Route path="/license" exact component={LicensePage} />
                  <Route path="/jobs" exact component={JobsPage} />
                  <Route path="/quiz" exact component={QuizPage} />
                  <Route path="/withdrawals" exact component={WithdrawPage} />
                  <Route
                    path="/notifications"
                    exact
                    component={NotificationPage}
                  />

                  <Route
                    render={function () {
                      return (
                        <div className="st-e-h">
                          <Error404 />
                        </div>
                      );
                    }}
                  />
                </Switch>
              </div>
              {login_layout ? <div className="st-empty-larg"></div> : ""}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(App);
