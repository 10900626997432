import React, { Component } from "react";
import { BounceRight, FadeIn, BounceUp, BounceLeft } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import {
  PlusCircle,
  Edit,
  Trash2,
  List,
  ArrowLeft,
  Eye,
  Mail,
  Command,
  CheckCircle,
  Users,
  Info,
  UserCheck,
  GitBranch,
  UserMinus,
  UserPlus,
} from "react-feather";
import Axios from "axios";
import { ErrorHandler, Home } from "../../global/Home";
import Spinner from "../../global/Spinner";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import img from "../../assets/svg/whocoded_avatar.svg";
import { open_right } from "../../store/actions/OpenRight";

class ListUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "All",
      detail: {},
      selected: {},
      searchValue: " ", // "  🔍",
    };
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }
  handleChangeSearch(event) {
    const searchText = event.target.value;
    this.setState({ searchValue: searchText });
    this.setState({ filter: "Search" });
  }

  Filter = (filter) => {
    // this.LoadData(filter);
    this.setState({ filter: filter });
  };
  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.setState({ switch: event.target.value });
      }
    }
  };

  Delete(key) {
    if (window.confirm("are you sure?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/deleteClient`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            ErrorHandler(err);
          });
      }
    }
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  MatchNow(id) {
    if (window.confirm("are you sure you?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading2: true });
        Axios.post(
          `${Home}admin/matchUser`,
          {
            id: id,
            id2: this.state.selected.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            this.setState({ loading2: false });
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
              this.OpenModal3("", {});
            } else {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  UnMatchNow(id) {
    if (window.confirm("are you sure you?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/UnmatchUser`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  AllowSeeShift(id) {
    if (window.confirm("are you sure you?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/activateusers`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
              this.OpenModal3("", {});
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  AllowSeeShift2(id) {
    if (window.confirm("are you sure you?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/suspendusers`,
          {
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
              this.OpenModal3("", {});
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            ErrorHandler(err);
          });
      }
    }
  }

  AutoVerifyUser(id) {
    console.log("iXXXd", id);
    if (
      window.confirm(
        "Are you sure you want to auto verify this user documents?"
      )
    ) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        // return;
        Axios.post(
          `${Home}admin/autoVerifyUser/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
              this.OpenModal3("", {});
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            ErrorHandler(err);
          });
      }
    }
  }

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log("XXXX", res);
          this.setState({ loading: false, data: res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  LoadData2 = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading2: true });
      Axios.get(`${Home}admin/ClientUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log("OOO", res);
          this.setState({ loading2: false, data2: res.data });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
    this.LoadData2(this.state.filter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    console.log("DDDDD", id);
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ detail: id });
  };

  // handleAutoVerify = (event) => {
  //   event.preventDefault();
  //   let token = "";
  //   if (
  //     localStorage.getItem("userToken") &&
  //     window.confirm(
  //       "Are you sure you want to auto verify this user documents?"
  //     )
  //   ) {
  //     // token = JSON.parse(localStorage.getItem("userToken"));
  //     // let fd = new FormData();
  //     // fd.append("name", this.state.premium);
  //     // fd.append("token", token);
  //     // this.setState({ loading: true });

  //     return;
  //     // Axios.post(`${Home}admin/sendNotification`, fd, {
  //     //   headers: {
  //     //     Authorization: `Bearer ${token}`,
  //     //   },
  //     // })
  //     //   .then((res) => {
  //     //     console.log("res", res);
  //     //     this.setState({ loading: false });
  //     //     if (res.data.success) {
  //     //       this.props.dispatch(launch_toaster(res.data.message));
  //     //       this.props.dispatch(toast_trigger(true));
  //     //       this.SwitchContent("", [0]);
  //     //     } else {
  //     //       this.props.dispatch(launch_toaster(res.data.message));
  //     //       this.props.dispatch(toast_trigger(false));
  //     //     }
  //     //   })
  //     //   .catch((err) => {
  //     //     console.log("err", err);

  //     //     this.setState({ loading: false });
  //     //     if (err.response) {
  //     //       const { data } = err.response;
  //     //       this.props.dispatch(launch_toaster(data.message));
  //     //       this.props.dispatch(toast_trigger(false));
  //     //     } else {
  //     //       this.props.dispatch(launch_toaster(err.message));
  //     //       this.props.dispatch(toast_trigger(false));
  //     //     }
  //     //   });
  //   }
  // };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }

  render() {
    const filterValue = this.state.filter;
    const searchValue = this.state.searchValue;

    const filteredArrayFun2 = this.state.data.filter(function (el) {
      if (filterValue !== "All") {
        if (filterValue === "Search") {
          if (el.phone_number && el.phone_number.includes(searchValue.trim())) {
            return el.phone_number;
          }
          if (el.email && el.email.includes(searchValue.trim())) {
            return el.email;
          }
          if (el.name && el.name.includes(searchValue.trim())) {
            return el.name;
          }
        } else {
          return el.userType === filterValue;
        }
      } else {
        return el.userType !== null;
      }
    });

    return (
      <>
        {this.state.modal === "WHOCODED" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="mt-4 mb-4">
                        <center>
                          <img
                            src={this.state.detail.picture}
                            className="img- shadow"
                          />
                        </center>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <tbody>
                            <tr>
                              <th>Name</th>
                              <td>{this.state.detail.name}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <td>{this.state.detail.email}</td>
                            </tr>
                            <tr>
                              <th>Mobile Number</th>
                              <td>{this.state.detail.phone_number}</td>
                            </tr>
                            <tr>
                              <th>State</th>
                              <td>{this.state.detail.state}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td>{this.state.detail.address}</td>
                            </tr>
                            <tr>
                              <th>Zip Code</th>
                              <td>{this.state.detail.zip_code}</td>
                            </tr>
                            <tr>
                              <th>Gender</th>
                              <td>
                                {this.state.detail.gender
                                  ? this.state.detail?.gender?.value
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th>Latino</th>
                              <td>
                                {this.state.detail.latino
                                  ? this.state.detail?.latino?.value
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h4 className="mt-3">User License</h4>
                      {this.state.detail.license &&
                        this.state.detail.license.length > 0 &&
                        this.state.detail.license.map((list, index) => (
                          <div className="table-responsive mt-3 mb-3">
                            <table
                              className="table mb-0 table-striped table-primary table-hover table-bordered"
                              id="stephen"
                            >
                              <tbody>
                                <>
                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license Number
                                    </th>
                                    <td>{list[`licenseNumber_${index}`]}</td>
                                  </tr>
                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license State
                                    </th>
                                    <td>{list[`issueState_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      Years of Experience
                                    </th>
                                    <td>{list[`yearsExp_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license Type
                                    </th>
                                    <td>{list[`licenseType_${index}`]}</td>
                                  </tr>
                                </>
                              </tbody>
                            </table>
                          </div>
                        ))}
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.AutoVerifyUser(this.state.detail.id)}
                    type="button"
                    className="btn btn-warning m-1"
                    data-dismiss="modal"
                  >
                    Auto Verify Details
                  </button>
                  {this.state.detail.see_shift ? (
                    <button
                      onClick={() => this.AllowSeeShift2(this.state.detail.id)}
                      type="button"
                      className="btn btn-dark m-1"
                      data-dismiss="modal"
                    >
                      Restrict from seeing shift
                    </button>
                  ) : (
                    <button
                      onClick={() => this.AllowSeeShift(this.state.detail.id)}
                      type="button"
                      className="btn btn-primary m-1"
                      data-dismiss="modal"
                    >
                      Allow see shift
                    </button>
                  )}

                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger m-1"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "WHOCODED2" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-m" role="document">
              <div className="modal-content">
                <div className=" modal-header">
                  <h5 className=" modal-title">Match {} to a client</h5>
                </div>
                <div className="modal-body">
                  {this.state.loading2 ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="table-responsive">
                        <table
                          className="table table-sm mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <tbody>
                            {this.state.data2.length > 0
                              ? this.state.data2.map((list, index) => (
                                  <tr key={index}>
                                    <td>{list.name}</td>
                                    <td>{list.email}</td>
                                    <td>
                                      <button
                                        onClick={() => this.MatchNow(list.id)}
                                        className="btn btn-primary st-btn2 btn-sm"
                                      >
                                        Match now
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">{this.state.filter} Users</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      {/* <input
                        id="outlined-basic"
                        label="Outlined"
                        variant="outlined"
                      /> */}
                      <input
                        type="text"
                        style={{
                          width: "150px",
                          height: "30px",
                          marginRight: "7px",
                          // borderColor: "#000",
                          borderRadius: "5px",
                        }}
                        placeholder="search"
                        value={this.state.searchValue}
                        onChange={this.handleChangeSearch}
                      />
                      <button
                        onClick={() => this.Filter("All")}
                        className="btn btn-sm btn-primary shadow m-1"
                      >
                        <List color="white" size={35} /> All
                      </button>
                      <button
                        onClick={() => this.Filter("Expert")}
                        className="btn btn-sm btn-success shadow m-1"
                      >
                        <List color="white" size={35} /> Experts
                      </button>
                      <button
                        onClick={() => this.Filter("Client")}
                        className="btn btn-sm btn-warning shadow m-1"
                      >
                        <List color="#000" size={35} /> Clients
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="table-responsive">
                  <table
                    className="table mb-0 table-striped table-hover table-bordered"
                    id="stephen"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Account Type</th>
                        <th>Phone Number</th>
                        <th>Zip Code</th>
                        <th>State</th>
                        <th>Address</th>
                        <th>Can see shift</th>
                        <th>isVerified</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredArrayFun2.map((dep, i) => (
                        <tr key={i}>
                          <td>
                            <img src={dep.picture} className="img-fluid" />
                          </td>
                          <td>{dep.name}</td>
                          <td>{dep.email}</td>
                          <th>
                            {dep.userType}
                            <span
                              data-rh={`Matched to ${dep.matched_cleint}`}
                              className="badge badge-primary badge-pill"
                            >
                              {dep.matched_cleint}
                            </span>
                          </th>
                          <td>{dep.phone_number}</td>
                          <td>{dep.zip_code}</td>

                          <td>{dep.zip_code}</td>
                          <td>{dep.address}</td>
                          <td>
                            {dep.see_shift ? (
                              <span className="badge badge-success">Yes</span>
                            ) : (
                              <span className="badge badge-danger">No</span>
                            )}
                          </td>
                          <td>
                            {dep.all_verified ? (
                              <span className="badge badge-success">True</span>
                            ) : (
                              <span className="badge badge-warning">False</span>
                            )}
                          </td>

                          <td>
                            <div>
                              <FadeIn duration="1s" timingFunction="ease-out">
                                <div className=" d-flex">
                                  <button
                                    data-rh="View details "
                                    onClick={() =>
                                      this.OpenModal3("WHOCODED", dep)
                                    }
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <Info color="white" size={35} />{" "}
                                  </button>

                                  {/* <button
                                    data-rh="Auto Verify user details "
                                    onClick={this.handleAutoVerify}
                                    className="btn btn-success btn-sm m-1"
                                  >
                                    <UserCheck color="white" size={35} />{" "}
                                  </button> */}

                                  {dep.userType === "Client" ? (
                                    <button
                                      data-rh="View Client's Experts "
                                      onClick={() =>
                                        this.SwitchContent("client_users", [
                                          dep.id,
                                          dep.name,
                                        ])
                                      }
                                      className="btn btn-dark btn-sm m-1"
                                    >
                                      <Users color="white" size={35} />{" "}
                                    </button>
                                  ) : (
                                    ""
                                  )}

                                  {dep.userType === "Expert" ? (
                                    dep.matched_cleint ? (
                                      <button
                                        data-rh="UnMatch expert from client"
                                        onClick={() => {
                                          // this.setState({selected:dep});
                                          // this.OpenModal3("WHOCODED2", dep)
                                          this.UnMatchNow(dep.id);
                                        }}
                                        className="btn btn-danger btn-sm m-1"
                                      >
                                        <UserMinus color="white" size={35} />{" "}
                                      </button>
                                    ) : (
                                      <button
                                        data-rh="Match expert to client"
                                        onClick={() => {
                                          this.setState({ selected: dep });
                                          this.OpenModal3("WHOCODED2", dep);
                                        }}
                                        className="btn btn-primary btn-sm m-1"
                                      >
                                        <UserPlus color="white" size={35} />{" "}
                                      </button>
                                    )
                                  ) : (
                                    ""
                                  )}

                                  <button
                                    data-rh="Delete"
                                    onClick={() => this.Delete(dep.id)}
                                    className="btn btn-danger btn-sm m-1 shadow"
                                  >
                                    <Trash2 color="white" size={35} />{" "}
                                  </button>
                                </div>
                              </FadeIn>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListUsers);
