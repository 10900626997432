import React, { Component } from "react";
import { BounceRight, FadeIn, BounceUp, BounceLeft } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../../store/actions/SwitchContent";
import { props_params } from "../../../store/actions/PropsParams";
import {
  PlusCircle,
  Edit,
  Trash2,
  List,
  ArrowLeft,
  Eye,
  Mail,
  Command,
  CheckCircle,
  FileText,
  User,
  Database,
  Check,
  Framer,
  Clock,
} from "react-feather";
import Axios from "axios";
import {
  ErrorHandler,
  formatAmount,
  Home,
  IMG_URL,
} from "../../../global/Home";
import Spinner from "../../../global/Spinner";
import { launch_toaster } from "../../../store/actions/IsToast";
import { toast_trigger } from "../../../store/actions/ToastTrigger";
import { open_right } from "../../../store/actions/OpenRight";
import { Link } from "react-router-dom";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ExportToCsv } from "export-to-csv";

class ListJobSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "all",
      detail: {},
      note: "",
      startDate: new Date(),
      startDate2: new Date(),
    };
  }

  ExportCSV = () => {
    var data = this.state.data;
    var exportData = [];
    if (data.length < 1) {
      this.props.dispatch(launch_toaster("No data to export"));
      this.props.dispatch(toast_trigger(false));
      return false;
    }

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const newObject = {
        Title: element.title,
        User: element?.user?.name,
        Amount: `$${element.amount}/hr`,
        Location: element.location,
        Start_Time: moment(element.user_start_time).format("MMM Do, h:mm:ss A"),
        End_Time: moment(element.user_end_time).format("MMM Do, h:mm:ss A"),
        Status: element.statusName,
      };
      exportData.push(newObject);
    }

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Care Giver Job Shift",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: "Care_Giver_Job_Shift",
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(exportData);
  };

  onChange2 = (startDate) => {
    this.setState({ startDate });
  };
  onChange3 = (startDate2) => {
    this.setState({ startDate2 });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.post(
        `${Home}admin/UpdateJobTime`,
        {
          start: moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.state.startDate2).format("YYYY-MM-DD HH:mm:ss"),
          id: this.state.detail._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.LoadData();
            this.OpenModal3("", {});
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response) {
            const { data } = err.response;
            this.props.dispatch(launch_toaster(data.message));
            this.props.dispatch(toast_trigger(false));
          } else {
            this.props.dispatch(launch_toaster(err.message));
            this.props.dispatch(toast_trigger(false));
          }
        });
    }
  };

  Filter = (filter) => {
    this.LoadData(filter);
    this.setState({ filter: filter });
  };
  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.setState({ switch: event.target.value });
      }
    }
  };

  Delete(key) {
    if (window.confirm("❌ are you sure you want to cancel?")) {
      let token = "";
      let reason = window.prompt("Reason for cancel");
      if (reason != null) {
        if (localStorage.getItem("userToken")) {
          token = JSON.parse(localStorage.getItem("userToken"));
          this.setState({ loading: true });
          Axios.post(
            `${Home}admin/CancelJob`,
            {
              id: key,
              reason: reason,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => {
              // console.log(res);
              if (res.data.success) {
                this.props.dispatch(launch_toaster(res.data.message));
                this.props.dispatch(toast_trigger(true));
                this.LoadData();
              } else {
                this.setState({ loading: false });
                this.props.dispatch(launch_toaster(res.data.message));
                this.props.dispatch(toast_trigger(false));
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }

  Delete1(key) {
    if (window.confirm("are you sure?")) {
      let token = "";
      // let reason = window.prompt("Reason for cancel");
      if (1 > 0) {
        if (localStorage.getItem("userToken")) {
          token = JSON.parse(localStorage.getItem("userToken"));
          this.setState({ loading: true });
          Axios.post(
            `${Home}admin/ConfirmJob`,
            {
              id: key,
              // reason:reason
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((res) => {
              // console.log(res);
              if (res.data.success) {
                this.props.dispatch(launch_toaster(res.data.message));
                this.props.dispatch(toast_trigger(true));
                this.LoadData();
              } else {
                this.setState({ loading: false });
                this.props.dispatch(launch_toaster(res.data.message));
                this.props.dispatch(toast_trigger(false));
              }
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }

  Approve(key) {
    if (window.confirm("are you sure you want to approve?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(
          `${Home}admin/approveDocument`,
          {
            id: key,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  Returned(key) {
    if (window.confirm("Are you sure you want to mark this returned?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}auth/settings/returnIssueItems`, {
          token: token,
          id: key,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData(this.state.filter);
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadData = (filter = this.state.filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      let id =
        this.props.data.params && this.props.data.params[0]
          ? this.props.data.params[0]
          : "";

      Axios.get(`${Home}admin/geJobs/${id}/${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
      if (modal === "WHOCODED4") {
        this.setState({
          startDate: new Date(id.user_start_time),
          startDate2: new Date(id.user_end_time),
        });
      }
    }
    this.setState({ detail: id });
    // console.log("DDDDDD", id);
  };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }

  Submit = () => {
    let base64 = this.sigPad.toDataURL();
    //  console.log(base64)
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      let id =
        this.props.data.params && this.props.data.params[0]
          ? this.props.data.params[0]
          : "";
      Axios.post(
        `${Home}profile/SignJob`,
        {
          picture: [base64],
          spervisor_name: this.state.name,
          job_id: this.state.detail._id,
          note: this.state.note,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.OpenModal3("", [0]);
            this.LoadData();
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          ErrorHandler(err);
          this.setState({ loading: false });
        });
    }
  };

  sigPad = {};
  clear = () => {
    this.sigPad?.clear();
  };
  // trim = () => {
  //   this.setState({trimmedDataURL: this.sigPad.getTrimmedCanvas()
  //     .toDataURL('image/png')})
  // }

  returnTitleName = () => {
    if (this.state.filter === "all") {
      return "";
    } else if (this.state.filter === "0") {
      return "Pending";
    } else if (this.state.filter === "4") {
      return "Confirmed";
    } else if (this.state.filter === "2") {
      return "Cancelled";
    } else if (this.state.filter === "3") {
      return "Completed";
    }
    return "";
  };

  render() {
    // console.log(this.state.detail)
    let a = moment(this.state.detail.user_start_time);
    let b = moment(this.state.detail.user_end_time);
    console.log(this.state.detail, this.state.detail.end_date);
    return (
      <>
        {this.state.modal === "WHOCODED" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="mt-4 mb-4">
                        <center>
                          <img
                            src={this.state.detail.picture}
                            className="img- shadow"
                          />
                        </center>
                      </div>
                      <div className="table-responsive">
                        <table
                          className="table mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <tbody>
                            <tr>
                              <th>Name</th>
                              <td>{this.state.detail.name}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <td>{this.state.detail.email}</td>
                            </tr>
                            <tr>
                              <th>Mobile Number</th>
                              <td>{this.state.detail.phone_number}</td>
                            </tr>
                            <tr>
                              <th>State</th>
                              <td>{this.state.detail.state}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td>{this.state.detail.address}</td>
                            </tr>
                            <tr>
                              <th>Zip Code</th>
                              <td>{this.state.detail.zip_code}</td>
                            </tr>
                            <tr>
                              <th>Gender</th>
                              <td>
                                {this.state.detail.gender
                                  ? this.state.detail?.gender?.value
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th>Latino</th>
                              <td>
                                {this.state.detail.latino
                                  ? this.state.detail?.latino?.value
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h4 className="mt-3">User License</h4>
                      {this.state.detail.license &&
                        this.state.detail.license.length > 0 &&
                        this.state.detail.license.map((list, index) => (
                          <div className="table-responsive mt-3 mb-3">
                            <table
                              className="table mb-0 table-striped table-primary table-hover table-bordered"
                              id="stephen"
                            >
                              <tbody>
                                <>
                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license Number
                                    </th>
                                    <td>{list[`licenseNumber_${index}`]}</td>
                                  </tr>
                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license State
                                    </th>
                                    <td>{list[`issueState_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      Years of Experience
                                    </th>
                                    <td>{list[`yearsExp_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: "30%" }}>
                                      license Type
                                    </th>
                                    <td>{list[`licenseType_${index}`]}</td>
                                  </tr>
                                </>
                              </tbody>
                            </table>
                          </div>
                        ))}

                      <h4 className="mt-3">User Documents</h4>
                      {
                        this.state.detail.documents &&
                          this.state.detail.documents.length > 0 && (
                            // this.state.detail.documents.map((list, index) => (
                            <div className="table-responsive mt-3 mb-3">
                              <table
                                className="table mb-0 table-striped table-primary table-hover table-bordered"
                                id="stephen"
                              >
                                <tbody>
                                  <>
                                    <tr>
                                      <th style={{ width: "30%" }}>
                                        Nursing License
                                      </th>
                                      <td>
                                        <a
                                          target={"_blank"}
                                          href={`${IMG_URL}uploads/documents/${this.state.detail.documents[0].file}`}
                                        >
                                          {this.state.detail.documents[0].file}
                                        </a>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style={{ width: "30%" }}>SSN/ITN </th>
                                      <td>
                                        <a
                                          target={"_blank"}
                                          href={`${IMG_URL}uploads/documents/${this.state.detail.documents[1].file}`}
                                        >
                                          {this.state.detail.documents[1].file}
                                        </a>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th style={{ width: "30%" }}>
                                        Photo Identification{" "}
                                      </th>
                                      <td>
                                        {this.state.detail?.documents[2] ? (
                                          <a
                                            target={"_blank"}
                                            href={`${IMG_URL}uploads/documents/${this.state.detail.documents[2].file}`}
                                          >
                                            {
                                              this.state.detail.documents[2]
                                                .file
                                            }
                                          </a>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </>
                                </tbody>
                              </table>
                            </div>
                          )
                        // ))
                      }
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "WHOCODED2" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {this.state.detail.picture ? (
                        <div className="mt-4 mb-4">
                          <center>
                            <img
                              src={this.state.detail.picture}
                              className="img- shadow"
                            />
                          </center>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="table-responsive">
                        <table
                          className="table mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <tbody>
                            <tr>
                              <th>Job Title</th>
                              <td>{this.state.detail.title}</td>
                            </tr>
                            <tr>
                              <th>Amount</th>
                              <td>
                                {formatAmount(this.state.detail.amount)}/hr
                              </td>
                            </tr>
                            <tr>
                              <th>Category</th>
                              <td>{this.state.detail.category}</td>
                            </tr>
                            <tr>
                              <th>Location</th>
                              <td>{this.state.detail.location}</td>
                            </tr>
                            <tr>
                              <th>Job start time</th>
                              <td>
                                {moment(this.state.detail.start_date).format(
                                  "MMM Do, h:mm:ss A"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Job end time</th>
                              <td>
                                {moment(this.state.detail.end_date).format(
                                  "MMM Do, h:mm:ss A"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Hours</th>
                              <td>{b.diff(a, "hours")} hours</td>
                            </tr>
                            <tr>
                              <th>User's Name</th>
                              <td>{this.state.detail.user?.name}</td>
                            </tr>
                            <tr>
                              <th>User Start Job</th>
                              <td>
                                {this.state.detail.user_start_time
                                  ? moment(
                                      this.state.detail.user_start_time
                                    ).format("MMM Do, h:mm:ss A")
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th>User End Job</th>
                              <td>
                                {this.state.detail.user_end_time
                                  ? moment(
                                      this.state.detail.user_end_time
                                    ).format("MMM Do, h:mm:ss A")
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <th>Job Description</th>
                              <td>{this.state.detail.desc}</td>
                            </tr>
                            <tr>
                              <th>Spervisor's Name</th>
                              <td>{this.state.detail.spervisor_name}</td>
                            </tr>
                            <tr>
                              <th>Spervisor's Signature</th>
                              <td>
                                <img
                                  style={{ width: "50%" }}
                                  src={this.state.detail.signature}
                                  className="img-fluid"
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Signed Time</th>
                              <td>
                                {this.state.detail.sign_time
                                  ? moment(this.state.detail.sign_time).format(
                                      "MMM Do, h:mm:ss A"
                                    )
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "WHOCODED3" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      {this.state.detail.picture ? (
                        <div className="mt-4 mb-4">
                          <center>
                            <img
                              src={this.state.detail.picture}
                              className="img- shadow"
                            />
                          </center>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="form-group mb-2">
                        <label>Spervisor's Name</label>
                        <input
                          value={this.state.name}
                          name="name"
                          required
                          placeholder="Josh Meris"
                          className="form-control st-login-f22"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="sign">
                        <SignaturePad
                          penColor="#7FB5FF"
                          canvasProps={{
                            style: {
                              width: 500,
                              height: 300,
                            },
                            placeholder: "Sign here",
                          }}
                          ref={(ref) => {
                            this.sigPad = ref;
                          }}
                        />
                      </div>

                      <div className="form-group mb-2 mt-3">
                        {/* <label>Spervisor's Note</label> */}
                        <textarea
                          value={this.state.note}
                          name="note"
                          placeholder="Type note"
                          className="form-control st-login-f22"
                          onChange={this.handleChange}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.Submit()}
                    type="button"
                    className="btn btn-primary"
                    // data-dismiss="modal"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => this.clear()}
                    type="button"
                    className="btn btn-warning"
                    // data-dismiss="modal"
                  >
                    Clear
                  </button>
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === "WHOCODED4" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <form
              onSubmit={this.handleSubmit}
              className="modal-dialog modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group ">
                            <label>
                              Start Time (
                              {moment(this.state.startDate).format(
                                "YYYY-MM-DD hh:mm:ss A"
                              )}
                              )
                            </label>
                            <DatePicker
                              // maxDate={new Date('2020-12-31')}
                              // minDate={new Date()}
                              timeFormat="HH:mm"
                              // dateFormat="MMMM d, yyyy h:mm aa"
                              required
                              showTimeSelect
                              showTimeInput
                              timeIntervals={5}
                              calendarClassName="rasta-stripes "
                              className="red-border st-login-f22 form-control"
                              selected={this.state.startDate}
                              onChange={(date) => this.onChange2(date)}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group ">
                            <label>
                              End Time (
                              {moment(this.state.startDate2).format(
                                "YYYY-MM-DD hh:mm:ss A"
                              )}
                              )
                            </label>
                            <DatePicker
                              // maxDate={new Date('2020-12-31')}
                              minDate={new Date(this.state.startDate)}
                              timeFormat="HH:mm"
                              // dateFormat="MMMM d, yyyy h:mm aa"
                              required
                              showTimeSelect
                              showTimeInput
                              timeIntervals={5}
                              calendarClassName="rasta-stripes "
                              className="red-border st-login-f22 form-control"
                              selected={this.state.startDate2}
                              onChange={(date) => this.onChange3(date)}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    // onClick={() => this.Submit()}
                    type="submit"
                    className="btn btn-primary"
                    // data-dismiss="modal"
                  >
                    Submit
                  </button>
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <h6 class="lh-5 mg-b-0">
                    All {this.returnTitleName()} Shifts
                  </h6>
                </div>
                <div className="col-md-8 me">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.Filter("all")}
                        className="btn btn-secondary shadow m-1 btn-sm"
                      >
                        <List color="white" size={35} /> All
                      </button>
                      <button
                        onClick={() => this.Filter("0")}
                        className="btn btn-secondary shadow m-1 btn-sm"
                      >
                        <List color="white" size={35} /> Pending
                      </button>
                      <button
                        onClick={() => this.Filter("4")}
                        className="btn btn-secondary shadow m-1 btn-sm"
                      >
                        <List color="#ffffff" size={35} /> Confirmed
                      </button>
                      <button
                        onClick={() => this.Filter("2")}
                        className="btn btn-secondary shadow m-1 btn-sm"
                      >
                        <List color="#ffffff" size={35} /> Cancelled
                      </button>
                      <button
                        onClick={() => this.Filter("3")}
                        className="btn btn-secondary shadow m-1 btn-sm"
                      >
                        <List color="#ffffff" size={35} /> Completed
                      </button>
                      <button
                        onClick={() => this.ExportCSV()}
                        className="btn btn-primary btn-sm m-1"
                      >
                        <FileText color="white" size={35} /> Export CSV
                      </button>
                      <button
                        onClick={() =>
                          this.SwitchContent("list_jobs", [
                            this.props.data.params[1],
                          ])
                        }
                        className="btn btn-danger btn-sm"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="table-responsive">
                  <table
                    className="table mb-0 table-striped table-hover table-bordered"
                    id="stephen"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}></th>
                        <th>Title</th>
                        <th>User</th>
                        <th>Amount</th>
                        <th>Location</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((dep, i) => (
                        <tr key={i}>
                          <td>
                            <FileText />
                          </td>
                          <td>{dep.title}</td>
                          <td>{dep.user?.name}</td>
                          <td>
                            <strong className="color-b">
                              {formatAmount(dep.amount)}/hr
                            </strong>
                          </td>
                          <td>{dep.location}</td>
                          <td>
                            {moment(dep.user_start_time).format(
                              "MMM Do, h:mm:ss A"
                            )}
                          </td>
                          <td>
                            {moment(dep.user_end_time).format(
                              "MMM Do, h:mm:ss A"
                            )}
                          </td>
                          <td
                            data-rh={
                              dep.statusReason
                                ? "Cancel Reason: " + dep.statusReason
                                : null
                            }
                          >
                            <span className={dep.statusClass}>
                              {dep.statusName}
                            </span>
                          </td>

                          <td>
                            <div>
                              <FadeIn duration="1s" timingFunction="ease-out">
                                <div className=" d-flex">
                                  {dep.status == 0 ? (
                                    <button
                                      data-rh="Confirm Job"
                                      onClick={() => this.Delete1(dep._id)}
                                      className="btn btn-success btn-sm m-1"
                                    >
                                      <Check color="white" size={35} />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  <button
                                    data-rh="View Job details"
                                    onClick={() =>
                                      this.OpenModal3("WHOCODED2", dep)
                                    }
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <Eye color="white" size={35} />
                                    {""}
                                  </button>

                                  <button
                                    data-rh="Append spervisor's signature"
                                    onClick={() =>
                                      this.OpenModal3("WHOCODED3", dep)
                                    }
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <Framer color="white" size={35} />
                                    {""}
                                  </button>

                                  <button
                                    data-rh="View User"
                                    onClick={() =>
                                      this.OpenModal3("WHOCODED", dep.user)
                                    }
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <User color="white" size={35} />
                                    {""}
                                  </button>

                                  <button
                                    data-rh="Adjust Time Shift"
                                    onClick={() =>
                                      this.OpenModal3("WHOCODED4", dep)
                                    }
                                    className="btn btn-dark btn-sm m-1"
                                  >
                                    <Clock color="white" size={35} />
                                    {""}
                                  </button>
                                  <button
                                    data-rh="Cancel Job"
                                    onClick={() => this.Delete(dep._id)}
                                    className="btn btn-danger btn-sm m-1"
                                  >
                                    <Trash2 color="white" size={35} />
                                  </button>
                                </div>
                              </FadeIn>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListJobSubmit);
