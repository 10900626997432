import React, { Component } from "react";
import { connect } from "react-redux";
import { open_menu } from "../store/actions/OpenMenu";
import { open_main_menu } from "../store/actions/MainMenu";
import { change_breadcrum } from "../store/actions/Bredcrum";
import StaffFacilitiesContainer from "../components/staffs/staff_facilities/StaffFacilitiesContainer";

class StaffClientPage extends Component {
  componentDidMount() {
    this.props.dispatch(open_menu("staff-facility"));
    this.props.dispatch(open_main_menu("staff-facility"));
    this.props.dispatch(change_breadcrum("Staff Facility"));
  }
  render() {
    let userDetails = this.props.data.userDetails;

    return (
      <div>
        <div className="mb-4">
          <center>{/* <img src={data} className="img-fluid" /> */}</center>
        </div>
        {userDetails.type == 3 && <StaffFacilitiesContainer />}
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(StaffClientPage);
