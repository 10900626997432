import React, { Component } from "react";
import { BounceRight, FadeIn } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../../store/actions/SwitchContent";
import { props_params } from "../../../store/actions/PropsParams";
import { PlusCircle, ArrowLeft, Trash, Edit } from "react-feather";
import { toast } from "react-toastify";
import { launch_toaster } from "../../../store/actions/IsToast";
import { toast_trigger } from "../../../store/actions/ToastTrigger";
import { Home } from "../../../global/Home";
import Axios from "axios";
import Spinner from "../../../global/Spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchUser from "../../../global/SearchUser";
import moment from "moment";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { Multiselect } from "multiselect-react-dropdown";

class AddClientJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      name: "",
      loading: false,
      data: [],
      department: "",
      level: "",
      details: [],
      lg: [],
      lgs: "",
      state: "",
      country: "",
      address: "",
      cancelGracePeriod: 24,
      startDate: new Date(),
      startDate2: new Date(),
      show: "",
      note: "",
      number: "",
      data2: [],
      markup: "",
      premium: "",
      net_premium: "",
      commission: "",
      company: "",
      dataf: [],
      seleted: [],
      steps: "",
      value: "",
      location: "",
      selectedValues: [],
      editIndex: null,
      jobnames: [],
      jobname: "",
      contractPrice: "",
    };
    this.fileInput = React.createRef();
  }

  onSelect = (selectedList, selectedItem) => {
    selectedItem.number = "1";
    selectedItem.amount = "0";
    console.log(selectedList);
    this.setState({ selectedValues: selectedList });
  };

  onRemove = (selectedList, removedItem) => {
    //console.log(selectedList,removedItem)
    this.setState({ selectedValues: selectedList });
  };

  onChange2 = (startDate) => {
    console.log("Starting...", moment(startDate).format("YYYY-MM-DD hh:mm:ss"));
    this.setState({ startDate });
  };
  onChange3 = (startDate2) => {
    this.setState({ startDate2 });
  };

  SelectRole(name) {
    let values = this.state.seleted.concat(name);
    let uniqueItems = Array.from(new Set(values));
    this.setState({ seleted: uniqueItems, markup: "" });
  }

  Remove = (name) => {
    if (this.state.seleted.includes(name)) {
      let values = this.state.seleted;
      let index = values.indexOf(name);
      this.state.seleted.splice(index, 1);
      this.setState({ seleted: this.state.seleted });
    }
  };

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "state") {
        if (this.state.details[event.target.value].lgas) {
          this.setState({ lg: this.state.details[event.target.value].lgas });
        }
      }
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let token = "";
    if (
      localStorage.getItem("userToken") &&
      window.confirm("Are you sure you want to post a job")
    ) {
      token = JSON.parse(localStorage.getItem("userToken"));
      let fd = new FormData();
      fd.append("name", this.state.premium);
      fd.append("step", this.state.steps);
      fd.append("validation", this.state.company);
      fd.append("desc", this.state.desc);
      fd.append("options", this.state.seleted);
      fd.append("token", token);
      this.setState({ loading: true });

      console.log("input", {
        title: this.state.premium,
        amount: this.state.steps,
        category: this.state.company,
        desc: this.state.desc,
        start: moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(this.state.startDate2).format("YYYY-MM-DD HH:mm:ss"),
        people: this.state.number,
        location: this.state.location,
        lat: this.state.lat,
        long: this.state.long,
        address2: this.state.address2,
        arra: this.state.selectedValues,
        facility_id: this.props.data.params[0],
      });
      // this.setState({ loading: false });

      // return;
      Axios.post(
        `${Home}admin/saveJob`,
        {
          title: this.props.data.params[1],
          amount: this.state.steps,
          category: this.state.company,
          desc: this.state.desc,
          start: moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(this.state.startDate2).format("YYYY-MM-DD HH:mm:ss"),
          people: this.state.number,
          location: this.state.location,
          lat: this.state.lat,
          long: this.state.long,
          address2: this.state.address2,
          arra: this.state.selectedValues,
          client_id: this.props.data.params[0],
          cancellation_time: this.state.cancelGracePeriod,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.SwitchContent("", [0]);
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response) {
            const { data } = err.response;
            this.props.dispatch(launch_toaster(data.message));
            this.props.dispatch(toast_trigger(false));
          } else {
            this.props.dispatch(launch_toaster(err.message));
            this.props.dispatch(toast_trigger(false));
          }
        });
    }
  };

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/getCategory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadDataFacility = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/geFacility`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log("getJobName", res);
          this.setState({ loading: false, jobnames: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadDataContractPrice = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/geClient/${this.props.data.userDetails._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log("CategoryP", res.data.payload);
          this.setState({
            loading: false,
            contractPrice: res.data.payload[0].contract_based_prices,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  Remove2 = (index) => {
    this.state.selectedValues.splice(index, 1);
    if (this.state.selectedValues.length < 2) {
      // this.state.seleted.splice(this.state.editIndex, 1)
    }
    this.setState({ selectedValues: this.state.selectedValues });
  };

  handleArrayChange = (event, index) => {
    let current = this.state.selectedValues[index];
    if (event.target.type !== "file") {
      if (this.state.selectedValues.length > 0) {
        if (event.target.name == "arrayTitle") {
          current.title = event.target.value;
        } else if (event.target.name == "arrayAmount") {
          current.amount = event.target.value;
        } else if (event.target.name == "arrayPoint") {
          current.number = event.target.value;
        }
        this.state.selectedValues[index] = current;
      }
      this.setState({ selectedValues: this.state.selectedValues });
    }
  };

  DisplayContractPrice = (list, amount, index) => {
    const contractPrice = this.state.contractPrice;
    for (let i = 0; i < contractPrice.length; i++) {
      if (
        contractPrice[i].job_category_id === list._id &&
        !Number(contractPrice[i].price) == 0
      ) {
        return (
          <input
            disabled="true"
            name="arrayAmount"
            value={contractPrice[i].price}
            onChange={(e) => this.handleArrayChange(e, index)}
            className="form-control form-control-st"
          />
        );
      }
    }
    return (
      <input
        name="arrayAmount"
        value={amount}
        onChange={(e) => this.handleArrayChange(e, index)}
        className="form-control form-control-st"
      />
    );
  };

  componentDidMount() {
    this.LoadData();
    this.LoadDataFacility();
    this.LoadDataContractPrice();

    console.log(" this.props.data.params[0]", this.props.data.params);
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };
  render() {
    return (
      <>
        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">Add Facility Job</h6>
                  <h6 class="lh-5 mg-b-0">
                    <b>Facility: {this.props.data.params[1]}</b>
                  </h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("dep_home", [0])}
                        className="btn btn-danger shadow"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              <form onSubmit={this.handleSubmit} className="mt-4 row">
                <div className="col-md-12">
                  {this.state.selectedValues.length > 0 && (
                    <div className="">
                      <table className="table table-sm table-striped table-secondary table-bordered table-hover">
                        <thead>
                          <tr>
                            <th>Category</th>
                            <th>Amount/hr</th>
                            <th>Nos of people needed</th>
                            <th style={{ width: "15%" }}>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.selectedValues.map((list, index) => (
                            <tr key={index}>
                              <td>{list.name}</td>
                              <td>
                                {/* <input
                                  name="arrayAmount"
                                  value={
                                    this.state.selectedValues[index]?.amount
                                  }
                                  onChange={(e) =>
                                    this.handleArrayChange(e, index)
                                  }
                                  className="form-control form-control-st"
                                /> */}
                                {this.DisplayContractPrice(
                                  list,
                                  this.state.selectedValues[index]?.amount,
                                  index
                                )}
                              </td>
                              <td>
                                <input
                                  name="arrayPoint"
                                  value={
                                    this.state.selectedValues[index]?.number
                                  }
                                  onChange={(e) =>
                                    this.handleArrayChange(e, index)
                                  }
                                  className="form-control form-control-st"
                                />
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.setState({ editIndex: index })
                                    }
                                    className="btn btn-sm btn-primary m-1"
                                  >
                                    <Edit />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => this.Remove2(`${index}`)}
                                    className="btn btn-sm btn-danger m-1"
                                  >
                                    <Trash />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <div className="form-group mt-5">
                    <label>Job Title</label>
                    <input
                      value={this.state.premium}
                      name="premium"
                      required
                      placeholder="Name"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div> */}

                  {/* <div className="form-group pr-4 mb-5">
                    <label>Facility Name</label>
                    <select
                      required
                      value={this.state.premium}
                      onChange={this.handleChange}
                      className="form-control st-login-f223"
                      style={{ width: "95%" }}
                      name="premium"
                    >
                      <option value="">Select one</option>
                      {this.state.jobnames.length > 0
                        ? this.state.jobnames.map((lis) => (
                            <option key={lis._id} value={lis._id}>
                              {lis.title}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div> */}
                </div>

                <div className="col-md-12 mt-3">
                  <div className="form-group mb-3 pr-4">
                    <label>Add categories</label>
                    <Multiselect
                      placeholder="Add categories"
                      closeOnSelect={false}
                      avoidHighlightFirstOption={true}
                      options={this.state.data} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelect} // Function will trigger on select event
                      onRemove={this.onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                    <small className=" text-muted">
                      Selecting multiple categories will be used to create jobs
                      for each category with other filled information{" "}
                    </small>
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Amount per hr</label>
                    <input
                      value={this.state.steps}
                      name="steps"
                      type="number"
                      placeholder="Steps"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}

                <div className="col-md-6">
                  <div className="form-group ">
                    <label>
                      Start Time (
                      {moment(this.state.startDate).format(
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                      )
                    </label>
                    <DatePicker
                      // maxDate={new Date('2020-12-31')}
                      // minDate={new Date()}
                      timeFormat="HH:mm"
                      // dateFormat="MMMM d, yyyy h:mm aa"
                      required
                      showTimeSelect
                      showTimeInput
                      timeIntervals={5}
                      calendarClassName="rasta-stripes "
                      className="red-border st-login-f22 form-control"
                      selected={this.state.startDate}
                      onChange={(date) => this.onChange2(date)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group ">
                    <label>
                      End Time (
                      {moment(this.state.startDate2).format(
                        "YYYY-MM-DD hh:mm:ss"
                      )}
                      )
                    </label>
                    <DatePicker
                      // maxDate={new Date('2020-12-31')}
                      minDate={new Date(this.state.startDate)}
                      timeFormat="HH:mm"
                      // dateFormat="MMMM d, yyyy h:mm aa"
                      required
                      showTimeSelect
                      showTimeInput
                      timeIntervals={5}
                      calendarClassName="rasta-stripes "
                      className="red-border st-login-f22 form-control"
                      selected={this.state.startDate2}
                      onChange={(date) => this.onChange3(date)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Grace Period Hours</label>
                    <input
                      value={this.state.cancelGracePeriod}
                      name="cancelGracePeriod"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Job Location</label>
                    {/* <input
                      value={this.state.location}
                      name="location"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    /> */}

                    <GooglePlacesAutocomplete
                      onLoadFailed={(err) => {
                        console.log(err);
                      }}
                      apiKey={"AIzaSyCUqJdhxiHToyjbSEedq-E01gKJDcDFrI4"}
                      selectProps={{
                        // singleValue: (provided) => ({
                        //   ...provided,
                        //   color: 'blue',
                        // }),
                        // value:this.state.location,

                        onChange: (place) => {
                          console.log(place);
                          geocodeByAddress(place.label)
                            .then((results) => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                              console.log(place, { lat, lng });
                              this.setState({
                                // value:place.label,
                                location: place.label,
                                lat: lat,
                                long: lng,
                              });
                            });
                        },
                        placeholder: "Search location",
                        // onKeyDown:(e)=>{
                        //   console.log("dsdss",e.target.value)
                        // }
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Actual Address</label>
                    <input
                      value={this.state.address2}
                      name="address2"
                      //   placeholder="Steps"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Number of people needed </label>
                    <input
                      value={this.state.number}
                      name="number"
                      //   placeholder="Steps"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      value={this.state.desc}
                      name="desc"
                      //   placeholder="Steps"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-12" style={{ marginTop: "1rem" }}>
                  <div className="form-group">
                    {this.state.loading ? (
                      <Spinner size={40} />
                    ) : (
                      <button className="btn st-btn2 btn-primary shadow">
                        Save Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(AddClientJob);
