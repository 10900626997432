import React, { Component } from "react";
import { BounceRight, FadeIn, BounceUp, BounceLeft } from "animate-components";
import { connect } from "react-redux";
import { switch_content } from "../../store/actions/SwitchContent";
import { props_params } from "../../store/actions/PropsParams";
import {
  PlusCircle,
  Edit,
  Trash2,
  List,
  ArrowLeft,
  Eye,
  Mail,
  Command,
  CheckCircle,
  FileText,
  User,
  Database,
  Edit2,
  Trash,
  BarChart,
  ToggleLeft,
  ToggleRight,
} from "react-feather";
import Axios from "axios";
import { formatAmount, Home } from "../../global/Home";
import Spinner from "../../global/Spinner";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import img from "../../assets/svg/whocoded_avatar.svg";
import { open_right } from "../../store/actions/OpenRight";
import moment from "moment/moment";
import { ExportToCsv } from 'export-to-csv';


class ListJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "all",
      detail: {},
      cat: [],
      faci: [],
      company: "",
      facility: "",
      jobnames: [],
      jobname: "",
      time: "0"
    };
  }

  ExportCSV = () => {
    var data = this.state.data;
    var exportData = [];
    if (data.length < 1) {
      this.props.dispatch(launch_toaster("No data to export"));
      this.props.dispatch(toast_trigger(false));
      return false;
    }

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const newObject = {
        Title: element.title,
        Category: element.category,
        Amount: `$${element.amount}`,
        Location: element.location,
        Descrition: element.desc
      }
      exportData.push(newObject)
    }

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Care Giver Jobs',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename:"Care_Giver_Jobs"
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(exportData);
  }

  Filter = (filter) => {
    this.LoadData(filter);
    this.setState({ filter: filter });
  };
  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "type") {
        this.setState({ switch: event.target.value });
      } else if (event.target.name === "facility") {
        this.LoadSearch(this.state.company, event.target.value, this.state.jobname, this.state.time)
      } else if (event.target.name === "company") {
        this.LoadSearch(event.target.value, this.state.facility, this.state.jobname, this.state.time)
      } else if (event.target.name === "jobname") {
        this.LoadSearch(this.state.company, this.state.facility, event.target.value, this.state.time)
      } else if (event.target.name === "time") {
        this.LoadSearch(this.state.company, this.state.facility, this.state.jobname, event.target.value)
      }
    }
  };

  Delete(key) {
    if (window.confirm("❌ are you sure you want to decline?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}admin/deleteJob`, {
          id: key,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }


  Toogle(key) {
    if (window.confirm("are you sure you want to toggle?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}admin/ToggleJob`, {
          id: key,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  Returned(key) {
    if (window.confirm("Are you sure you want to mark this returned?")) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}auth/settings/returnIssueItems`, {
          token: token,
          id: key,
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData(this.state.filter);
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadSearch = (cat, facility, jobname, time) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/searchJobs/${cat ? cat : "none"}/${facility ? facility : "none"}/${jobname ? jobname : "none"}/${time}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/geJobs`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };


  LoadDataCat = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/getCategory`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, cat: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadDataFacity = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/geFacility`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, faci: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadDataJobName = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/getJobName`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, jobnames: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
    this.LoadDataCat();
    this.LoadDataFacity();
    this.LoadDataJobName();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ detail: id });
  };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }


  render() {

    return (
      <>
        {this.state.modal === "WHOCODED" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  {this.state.loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="mt-4 mb-4">
                        <center>
                          <img src={this.state.detail.picture} className="img- shadow" />
                        </center>

                      </div>
                      <div className="table-responsive">
                        <table
                          className="table mb-0 table-striped table-hover table-bordered"
                          id="stephen"
                        >
                          <tbody>
                            <tr>
                              <th>Name</th>
                              <td>{this.state.detail.name}</td>
                            </tr>
                            <tr>
                              <th>Email</th>
                              <td>{this.state.detail.email}</td>
                            </tr>
                            <tr>
                              <th>Mobile Number</th>
                              <td>{this.state.detail.phone_number}</td>
                            </tr>
                            <tr>
                              <th>State</th>
                              <td>{this.state.detail.state}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td>{this.state.detail.address}</td>
                            </tr>
                            <tr>
                              <th>Zip Code</th>
                              <td>{this.state.detail.zip_code}</td>
                            </tr>
                            <tr>
                              <th>Gener</th>
                              <td>{this.state.detail.gender ? this.state.detail?.gender?.value : ''}</td>
                            </tr>
                            <tr>
                              <th>Latino</th>
                              <td>{this.state.detail.latino ? this.state.detail?.latino?.value : ''}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <h4 className="mt-3">User License</h4>
                      {this.state.detail.license && this.state.detail.license.length > 0 && (
                        this.state.detail.license.map((list, index) =>
                          <div className="table-responsive mt-3 mb-3">
                            <table
                              className="table mb-0 table-striped table-primary table-hover table-bordered"
                              id="stephen"
                            >
                              <tbody>
                                <>
                                  <tr key={index}>
                                    <th style={{ width: '30%' }}>license Number</th>
                                    <td>{list[`licenseNumber_${index}`]}</td>
                                  </tr>
                                  <tr key={index}>
                                    <th style={{ width: '30%' }}>license State</th>
                                    <td>{list[`issueState_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: '30%' }}>Years of Experience</th>
                                    <td>{list[`yearsExp_${index}`]}</td>
                                  </tr>

                                  <tr key={index}>
                                    <th style={{ width: '30%' }}>license Type</th>
                                    <td>{list[`licenseType_${index}`]}</td>
                                  </tr>
                                </>
                              </tbody>
                            </table>
                          </div>
                        )
                      )}




                    </>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    onClick={() => this.OpenModal3("", {})}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>



            </div>
          </div>
        ) : (
          ""
        )}


        {this.state.modal === "WHOCODED2" ? (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <>

                    <form className="mt-2">
                      <div className="form-group pr-4 pb-4">
                        <label>Category</label>
                        <select
                          required
                          value={this.state.company}
                          onChange={this.handleChange}
                          className="form-control st-login-f223"
                          style={{ width: "95%" }}
                          name="company"
                        >
                          <option value="">Select one</option>
                          <option value={"none"}>All Categories</option>
                          {this.state.cat.length > 0 ?
                            this.state.cat.map(lis =>
                              <option key={lis._id} value={lis._id}>{lis.name}</option>
                            )
                            : ''}

                        </select>
                      </div>

                      <div className="form-group pr-4 pt-4 pb-4">
                        <label>Facility</label>
                        <select
                          required
                          value={this.state.facility}
                          onChange={this.handleChange}
                          className="form-control st-login-f223"
                          style={{ width: "95%" }}
                          name="facility"
                        >
                          <option value="">Select one</option>
                          <option value={"none"}>All Facility</option>
                          {this.state.faci.length > 0 ?
                            this.state.faci.map(lis =>
                              <option key={lis._id} value={lis._id}>{lis.title}</option>
                            )
                            : ''}

                        </select>
                      </div>


                      <div className="form-group pr-4 pt-4 pb-4">
                        <label>Job Name</label>
                        <select
                          required
                          value={this.state.jobname}
                          onChange={this.handleChange}
                          className="form-control st-login-f223"
                          style={{ width: "95%" }}
                          name="jobname"
                        >
                          <option value="">Select one</option>
                          <option value={"none"}>All Job Names</option>
                          {this.state.jobnames.length > 0 ?
                            this.state.jobnames.map(lis =>
                              <option key={lis._id} value={lis._id}>{lis.name}</option>
                            )
                            : ''}

                        </select>
                      </div>

                      <div className="form-group pr-4 pt-4">
                        <label>Order By Time</label>
                        <select
                          required
                          value={this.state.time}
                          onChange={this.handleChange}
                          className="form-control st-login-f223"
                          style={{ width: "95%" }}
                          name="time"
                        >
                          <option value="">Select one</option>
                          <option value={"0"}>Default Order</option>
                          <option value={"1"}>Start Time Ascending</option>
                          <option value={"2"}>Start Time Decending</option>
                          <option value={"3"}>End Time Ascending</option>
                          <option value={"4"}>End Time Decending</option>
                        </select>
                      </div>
                    </form>



                  </>
                </div>

                <div className="modal-footer mt-3">
                  <button
                    onClick={() => {
                      this.OpenModal3("", {})
                      this.setState({ facility: "", company: "", jobname: "" })
                    }}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>



            </div>
          </div>
        ) : (
          ""
        )}

        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">Jobs</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("add_job", 0)}
                        //   onClixpck={() => this.OpenModal3("WHOCODED", '')}
                        className="btn btn-primary  m-1"
                      >
                        <PlusCircle color="white" size={35} /> Add New
                      </button>

                      <button
                        onClick={() => this.OpenModal3("WHOCODED2", '')}
                        //   onClixpck={() => this.OpenModal3("WHOCODED", '')}
                        className="btn btn-secondary  m-1"
                      >
                        <BarChart color="white" size={35} /> Filter Jobs
                      </button>

                      <button
                        onClick={() => this.SwitchContent("del_jobs", 0)}
                        //   onClixpck={() => this.OpenModal3("WHOCODED", '')}
                        className="btn btn-danger  m-1"
                      >
                        <Trash color="white" size={35} /> Deleted Jobs
                      </button>

                      <button
                        onClick={() => this.ExportCSV()}
                        //   onClixpck={() => this.OpenModal3("WHOCODED", '')}
                        className="btn btn-dark  m-1"
                      >
                        <FileText color="white" size={35} /> Export CSV
                      </button>

                      {/* <button onClick={()=>this.Filter('all')} className="btn btn-primary shadow m-1"><List color="white" size={35} /> All</button>
                           <button onClick={()=>this.Filter('returned')} className="btn btn-success shadow m-1"><List color="white" size={35} /> Returned</button>
                           <button onClick={()=>this.Filter('unreturned')} className="btn btn-warning shadow m-1"><List color="#000" size={35} /> Pending</button> */}
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="table-responsive">
                  <table
                    className="table mb-0 table-striped table-hover table-bordered"
                    id="stephen"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: '5%' }}></th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Location</th>
                        <th className={`${this.state.time == "1" || this.state.time == "2" ? "alert-success" : ""}`}>Start Time </th>
                        <th className={`${this.state.time == "3" || this.state.time == "4" ? "alert-success" : ""}`}>End Time</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((dep, i) => (
                        <tr key={i}>
                          <td>
                            <FileText />
                          </td>
                          <td>{dep.title}</td>
                          <td>{dep.category}</td>
                          <td><strong className="color-b">{formatAmount(dep.amount)}/hr</strong></td>
                          <td>{dep.location}</td>
                          <td>{moment(dep.start_date).format("MMM Do, h:mm:ss A")}</td>
                          <td>{moment(dep.end_date).format("MMM Do, h:mm:ss A")}</td>


                          <td>{dep.desc && dep.desc.length > 60 ? dep.desc.slice(0, 60) + "..." : dep.desc}</td>
                          {/* <td>{dep.address}</td> */}
                          <td>

                            <div>
                              <FadeIn duration="1s" timingFunction="ease-out">
                                <div className=" d-flex">
                                  <button
                                    data-rh="View Job Activities"
                                    onClick={() =>
                                      this.SwitchContent("cred_files", [dep._id])
                                    }
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <Database color="white" size={35} />{" "}
                                  </button>

                                  <button
                                    data-rh="Edit"
                                    onClick={() => this.SwitchContent("edit_job", [dep._id])}
                                    className="btn btn-primary btn-sm m-1"
                                  >
                                    <Edit2 color="white" size={35} />{" "}
                                  </button>



                                  {dep.toggle ?
                                    <button
                                      data-rh="Toggle Off Job"
                                      onClick={() => this.Toogle(dep._id)}
                                      className="btn btn-dark btn-sm m-1 shadow"
                                    >
                                      <ToggleLeft color="white" size={35} />{" "}
                                    </button>

                                    :
                                    <button
                                      data-rh="Toggle On Job"
                                      onClick={() => this.Toogle(dep._id)}
                                      className="btn btn-success btn-sm m-1 shadow"
                                    >
                                      <ToggleRight color="white" size={35} />{" "}
                                    </button>
                                  }

                                  <button
                                    data-rh="Delete"
                                    onClick={() => this.Delete(dep._id)}
                                    className="btn btn-danger btn-sm m-1 shadow"
                                  >
                                    <Trash2 color="white" size={35} />{" "}
                                  </button>

                                </div>
                              </FadeIn>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListJobs);
