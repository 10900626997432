import React, { Component } from "react";
import { connect } from "react-redux";
import ListClient from "./ListClient";
import AddClient from "./AddClient";
import EditClient from "./EditClient";
import ClientContract from "./ClientContract";

class ClientContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      current: "List",
    };
  }

  Switcher = () => {
    let current = this.props.data.switch;
    switch (current) {
      case "edit_job":
        return <EditClient />;
      case "add_job":
        return <AddClient />;
      case "add_contract":
        return <ClientContract />;
      default:
        return <ListClient />;
    }
  };

  render() {
    return <div>{this.Switcher()}</div>;
  }
}

const mapStateToProps = (store) => ({
  data: store,
});

export default connect(mapStateToProps)(ClientContainer);
