import Axios from "axios";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Home } from "../../../global/Home";
import { FadeIn } from "animate-components";
import Spinner from "../../../global/Spinner";
import { open_right } from "../../../store/actions/OpenRight";
import { ArrowLeft, User, GitPullRequest } from "react-feather";
import { launch_toaster } from "../../../store/actions/IsToast";
import { props_params } from "../../../store/actions/PropsParams";
import { toast_trigger } from "../../../store/actions/ToastTrigger";
import { switch_content } from "../../../store/actions/SwitchContent";
import moment from "moment/moment";

class LinkStaffToClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "all",
      detail: {},
      selectedFacility: [],
    };
  }
  Filter = (filter) => {
    this.LoadData(filter);
    this.setState({ filter: filter });
  };

  handleCheckBox = (event) => {
    console.log("CheckBox event", event);
    if (event.target.name === "facility") {
      console.log("CheckBox Target", event.target);
      console.log("CheckBox Target", event.target.value);
      const selectedFacility = this.state.selectedFacility;

      const result = selectedFacility.filter((item) => {
        if (item == event.target.value) {
          return true;
        }
      });

      // if (this.state.details[event.target.value].lgas) {
      //   this.setState({ lg: this.state.details[event.target.value].lgas });
      // }
    }
    // if (event.target.type !== "files") {
    //   this.setState({ [event.target.name]: event.target.value });
    //   if (event.target.name === "state") {
    //     if (this.state.details[event.target.value].lgas) {
    //       this.setState({ lg: this.state.details[event.target.value].lgas });
    //     }
    //   }
    // }
  };

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  AttachStaff(facilityId) {
    if (
      window.confirm(
        `Are you sure you want to attach this facility to this staff (${this.props.data.params[1]})?`
      )
    ) {
      let token = "";
      const input = {
        staff_id: this.props.data.params[0],
        facilities: [facilityId],
      };
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        Axios.post(`${Home}admin/staff/attach_facility`, input, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData(this.state.filter);
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
            if (err.response) {
              const { data } = err.response;
              this.props.dispatch(launch_toaster(data.message));
              this.props.dispatch(toast_trigger(false));
            } else {
              this.props.dispatch(launch_toaster(err.message));
              this.props.dispatch(toast_trigger(false));
            }
          });
      }
    }
  }

  DetachStaff(facilityId) {
    if (
      window.confirm("Are you sure you want to detach facility from staff?")
    ) {
      let token = "";
      if (localStorage.getItem("userToken")) {
        token = JSON.parse(localStorage.getItem("userToken"));
        this.setState({ loading: true });
        const input = {
          staff_id: this.props.data.params[0],
          facilities: [facilityId],
        };
        Axios.post(`${Home}admin/staff/detach_facility`, input, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            // console.log(res);
            if (res.data.success) {
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(true));
              this.LoadData();
            } else {
              this.setState({ loading: false });
              this.props.dispatch(launch_toaster(res.data.message));
              this.props.dispatch(toast_trigger(false));
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(
        `${Home}admin/staff/${this.props.data.params[0]}/facility_check`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log("IOSDS", res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    console.log("Params", this.props.data.params);
    this.LoadData(this.state.filter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ detail: id });
  };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }

  render() {
    return (
      <>
        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">KareGiver Facilities</h6>
                  <h6 class="lh-5 mg-b-0">
                    <b>Staff: {this.props.data.params[1]}</b>
                  </h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("dep_home", [0])}
                        className="btn btn-danger shadow"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="table-responsive">
                  <table
                    className="table mb-0 table-striped table-hover table-bordered"
                    id="stephen"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}></th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Location</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((facilities, i) => (
                        <tr key={i}>
                          <td>
                            <User />
                            {/* <input
                              type="checkbox"
                              value={facilities._id}
                              name="facility"
                              onChange={this.handleCheckBox}
                            /> */}
                          </td>
                          <td>{facilities.title}</td>
                          <td>{facilities.phone}</td>
                          <td>{facilities.email}</td>
                          <td>{facilities.location}</td>

                          <td>
                            <div>
                              <FadeIn duration="1s" timingFunction="ease-out">
                                <div className=" d-flex">
                                  {facilities.user_belongs ? (
                                    <button
                                      data-rh="Detach staff form facility"
                                      onClick={() =>
                                        this.DetachStaff(facilities.id)
                                      }
                                      className="btn btn-danger btn-sm m-1"
                                    >
                                      <GitPullRequest color="white" size={35} />{" "}
                                    </button>
                                  ) : (
                                    <button
                                      data-rh="Attach to staff"
                                      onClick={() =>
                                        this.AttachStaff(facilities.id)
                                      }
                                      className="btn btn-primary btn-sm m-1"
                                    >
                                      <GitPullRequest color="white" size={35} />{" "}
                                    </button>
                                  )}
                                </div>
                              </FadeIn>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <button
                  className="btn btn-success btn-sm m-4 pull-right"
                  type="submit"
                >
                  Facilities Bulk Attach
                </button> */}
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(LinkStaffToClient);
