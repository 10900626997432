// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCEfYUvd-PvyZfc2GsuxaD4Q_NqMS8SAbg",
//   authDomain: "karegiver-app.firebaseapp.com",
//   projectId: "karegiver-app",
//   storageBucket: "karegiver-app.appspot.com",
//   messagingSenderId: "406903408992",
//   appId: "1:406903408992:web:0f589b9f5bd5d39effb605",
//   measurementId: "G-1GCLGRQ6XK"
// };

// // Initialize Firebase
// const firebase = initializeApp(firebaseConfig);
// // const analytics = getAnalytics(app);

// export default firebase;

import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyCEfYUvd-PvyZfc2GsuxaD4Q_NqMS8SAbg",
  authDomain: "karegiver-app.firebaseapp.com",
  projectId: "karegiver-app",
  storageBucket: "karegiver-app.appspot.com",
  messagingSenderId: "406903408992",
  appId: "1:406903408992:web:0f589b9f5bd5d39effb605",
  measurementId: "G-1GCLGRQ6XK",
};
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const app = initializeApp(firebaseConfig);
      return getMessaging(app);
    }
    console.log("Firebase does not support this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const requestPermission = async () => {
  console.log("Requesting User Permission......");
  const isSupportedBrowser = await isSupported();

  if (isSupportedBrowser) {
    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted.");
        try {
          const messagingResolve = await messaging;
          const currentToken = await getToken(messagingResolve, {
            vapidKey: `Notification_key_pair`,
          });
          if (currentToken) {
            console.log("Client Token: ", currentToken);
          }
        } catch (err) {
          console.log("An error occurred while retrieving token. ", err);
        }

        // return getToken(messaging, { vapidKey: `Notification_key_pair` })
        //   .then((currentToken) => {
        //     if (currentToken) {
        //       console.log("Client Token: ", currentToken);
        //     } else {
        //       console.log("Failed to generate the app registration token.");
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(
        //       "An error occurred when requesting to receive the token.",
        //       err
        //     );
        //   });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }
};

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
