import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { Component } from "react";
import { Home } from "../../global/Home";
import Spinner from "../../global/Spinner";
import SearchUser from "../../global/SearchUser";
import "react-datepicker/dist/react-datepicker.css";
import { BounceRight, FadeIn } from "animate-components";
import { props_params } from "../../store/actions/PropsParams";
import { PlusCircle, ArrowLeft, Trash } from "react-feather";
import { launch_toaster } from "../../store/actions/IsToast";
import { toast_trigger } from "../../store/actions/ToastTrigger";
import { switch_content } from "../../store/actions/SwitchContent";

class EditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      name: "",
      loading: false,
      category: [],
      categoryPrice: [],
      inputValues: {},
    };
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    this.LoadDataContractPrice();
    this.LoadDataCategories();

    // console.log("this.props.data.params[0]", this.props.data.params[0]);
  }

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    const tempValue = this.state.inputValues;
    const key = event.target.name;
    let value = event.target.value;

    if (!Number(value)) {
      value = 0;
    }
    tempValue[key] = value;
    // console.log("tempValue", tempValue);
    this.setState({ inputValues: tempValue });
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });

      const categories = this.state.category;
      const myInput = this.state.inputValues;
      let contractPrices = [];
      for (let i = 0; i < categories.length; i++) {
        const key = categories[i]._id;
        if (myInput.hasOwnProperty(key)) {
          contractPrices.push({
            price: myInput[key],
            job_category_id: key,
            client: this.props.data.params[0],
          });
        }
      }

      console.log("contractPrices", contractPrices);

      Axios.post(
        `${Home}admin/storeContractBasedPrices`,
        {
          prices: [...contractPrices],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.SwitchContent("", [0]);
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response) {
            const { data } = err.response;
            this.props.dispatch(launch_toaster(data.message));
            this.props.dispatch(toast_trigger(false));
          } else {
            this.props.dispatch(launch_toaster(err.message));
            this.props.dispatch(toast_trigger(false));
          }
        });
    }
  };

  LoadDataCategories = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/getCategory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log("Category", res.data.payload);
          this.setState({ loading: false, category: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  LoadDataContractPrice = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/geClient/${this.props.data.params[0]}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          // console.log("CategoryP", res.data.payload);
          this.setState({
            loading: false,
            categoryPrice: res.data.payload[0].contract_based_prices,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  ComputeOldValue = (categoryId) => {
    const categoryPriced = this.state.categoryPrice;
    if (this.state.inputValues.hasOwnProperty(categoryId)) {
      return;
    }
    if (categoryPriced) {
      for (let i = 0; i < categoryPriced.length; i++) {
        if (categoryPriced[i].job_category_id === categoryId) {
          return categoryPriced[i].price;
        }
      }
    }
    return;
  };

  LoadCategoryFields = () => {
    if (this.state.category.length === 0) {
      return (
        <div className="col-md-6">
          <text>Job category not found</text>{" "}
        </div>
      );
    }
    const categories = this.state.category;
    return categories.map((category, index) => {
      return (
        <div className="col-md-12" key={index}>
          <div className="form-group col-md-6">
            <label>{category.name}</label>
            <input
              // value={this.state.inputValues[category._id]}
              value={
                this.state.inputValues[category._id] ||
                this.ComputeOldValue(category._id)
              }
              name={category._id}
              type="string"
              placeholder="0"
              className="form-control st-login-f22"
              onChange={this.handleChange}
            />
          </div>
        </div>
      );
    });
  };

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };
  render() {
    return (
      <>
        {this.state.name === "WHOCODED" ? (
          <SearchUser
            show={this.state.show}
            display={this.state.name === "WHOCODED" ? "block" : "none"}
            close={() => this.OpenModal("", 0)}
          />
        ) : (
          ""
        )}
        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 className="lh-5 mg-b-0">Client Contract</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("dep_home", [0])}
                        className="btn btn-danger shadow"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              <form onSubmit={this.handleSubmit} className="mt-4 row">
                {this.LoadCategoryFields()}

                {this.state.category.length > 0 ? (
                  <div className="col-md-12" style={{ marginTop: "1rem" }}>
                    <div className="form-group">
                      {this.state.loading ? (
                        <Spinner size={40} />
                      ) : (
                        <button className="btn st-btn2 btn-primary shadow">
                          Save Now
                        </button>
                      )}
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(EditClient);
