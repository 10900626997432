import Axios from "axios";
import { connect } from "react-redux";
import { Home } from "../../../global/Home";
import React, { Component } from "react";
import Spinner from "../../../global/Spinner";
import { FadeIn } from "animate-components";
import { PlusCircle, Trash2, Briefcase, User, List, Eye } from "react-feather";
import { open_right } from "../../../store/actions/OpenRight";
import { launch_toaster } from "../../../store/actions/IsToast";
import { props_params } from "../../../store/actions/PropsParams";
import { toast_trigger } from "../../../store/actions/ToastTrigger";
import { switch_content } from "../../../store/actions/SwitchContent";

class ListStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      List: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      loading: false,
      data: [],
      name: "",
      modal: "",
      show: "",
      id: 0,
      switch: "",
      filter: "all",
      detail: {},
    };
  }

  OpenModal = (name, id) => {
    if (name.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ name: name }), 600);
    } else {
      this.setState({ name: name });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id: id });
  };

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/staff`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  componentDidMount() {
    this.LoadData(this.state.filter);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.quick_params !== this.props.data.quick_params) {
      this.LoadData(this.state.filter);
    }
    if (nextProps.data.switch !== this.props.data.switch) {
      this.LoadData(this.state.filter);
    }
  }

  OpenModal3 = (modal, id) => {
    if (id !== 0 || id > 0) {
      // this.LoadFiles(id);
    }
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal: modal }), 600);
    } else {
      this.setState({ modal: modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ detail: id });
  };

  componentWillUnmount() {
    this.props.dispatch(open_right("Open"));
  }

  render() {
    return (
      <>
        <FadeIn duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">KareGiver Staffs</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("add_staff", 0)}
                        className="btn btn-primary shadow"
                      >
                        <PlusCircle color="white" size={35} /> Add New
                      </button>

                      {/* <button onClick={()=>this.Filter('all')} className="btn btn-primary shadow m-1"><List color="white" size={35} /> All</button>
                           <button onClick={()=>this.Filter('returned')} className="btn btn-success shadow m-1"><List color="white" size={35} /> Returned</button>
                           <button onClick={()=>this.Filter('unreturned')} className="btn btn-warning shadow m-1"><List color="#000" size={35} /> Pending</button> */}
                    </FadeIn>
                  </div>
                </div>
              </div>
            </div>
            {this.state.loading ? (
              <div className="p-5">
                <Spinner size={70} />
              </div>
            ) : this.state.data.length < 1 ? (
              <div className="p-5">
                <div className="alert alert-warning text-center">
                  No data yet
                </div>
              </div>
            ) : (
              <div className="p-3">
                <div className="table-responsive">
                  <table
                    className="table mb-0 table-striped table-hover table-bordered"
                    id="stephen"
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((staff, i) => (
                        <tr key={i}>
                          <td>
                            <User />
                          </td>
                          <td>
                            {staff.first_name} {staff.last_name}
                          </td>
                          <td>{staff.email}</td>
                          <td>{staff.created_at.substring(0, 10)}</td>
                          {/* <td>{dep.location}</td> */}

                          {/* <td>{dep.address2}</td> */}
                          <td>
                            <div>
                              <FadeIn duration="1s" timingFunction="ease-out">
                                <div className=" d-flex">
                                  <button
                                    data-rh="Link staff to facilities"
                                    onClick={() =>
                                      this.SwitchContent(
                                        "staff_to_facilities",
                                        [
                                          staff._id,
                                          staff.first_name +
                                            " " +
                                            staff.last_name,
                                        ]
                                      )
                                    }
                                    className="btn btn-success btn-sm m-1"
                                  >
                                    <Briefcase color="white" size={35} />{" "}
                                  </button>

                                  <button
                                    data-rh="View Staff facilities"
                                    onClick={() =>
                                      this.SwitchContent(
                                        "list_staff_facilities",
                                        [
                                          staff._id,
                                          staff.first_name +
                                            " " +
                                            staff.last_name,
                                        ]
                                      )
                                    }
                                    className="btn btn-info btn-sm m-1 shadow"
                                  >
                                    <Eye color="white" size={35} />{" "}
                                  </button>
                                </div>
                              </FadeIn>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(ListStaff);
