import React, { Component } from "react";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logotwo.png";
import bar from "../assets/img/bar.png";
import { connect } from "react-redux";
import { open_menu } from "../store/actions/OpenMenu";
import { open_main_menu } from "../store/actions/MainMenu";
import {
  Home,
  User,
  Settings,
  Users,
  LogOut,
  DollarSign,
  File,
  FileText,
  Cast,
  GitMerge,
  GitBranch,
  Briefcase,
  Award,
  HelpCircle,
  Cpu,
  Slack,
  Bell,
  AtSign,
} from "react-feather";
import { BounceRight, FadeIn, BounceLeft } from "animate-components";

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop: "",
      mobile: false,
    };
  }

  OpenMenu = (name, main_name) => {
    this.props.dispatch(open_menu(name));
    this.props.dispatch(open_main_menu(main_name));
  };

  OpenMobileMenu = () => {
    if (window.pageYOffset <= 700) {
      this.setState({ mobile: !this.state.mobile });
    }
  };

  render() {
    // console.log("----",this.props.data.userDetails);
    let userDetails = this.props.data.userDetails;
    return (
      <>
        <FadeIn duration="1s" timingFunction="ease-out">
          <aside
            className={`aside aside-fixed ${
              this.state.mobile ? "mo-aside" : "de-aside"
            }`}
          >
            <div className="aside-header " style={{}}>
              <Link
                onClick={() => this.OpenMobileMenu()}
                to="#"
                className="aside-logo"
              >
                <img
                  className="img-fluid st-logo-img-dashboard"
                  src={logo}
                  // style={{ marginLeft: "6px" }}
                  alt="Logo"
                />
              </Link>
              <Link
                onClick={() => this.OpenMobileMenu()}
                to="#"
                className="aside-menu-link only-mo"
              >
                <span>
                  <i>
                    {/* <Codepen color="rgba(27, 46, 75, 0.9)" size={48} /> */}
                  </i>
                  <span>
                    <img className="img-fluid barr" src={bar} />
                    {/* <MaterialIcon icon="menu" /> */}
                  </span>
                </span>
              </Link>
            </div>

            <div className="aside-body st-scroll ps--active-y bg-white p-0">
              <ul
                className="nav nav-aside st-nav-aside"
                style={{ transition: "0.5s" }}
              >
                {userDetails.type == 1 && (
                  <>
                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "dashboard" ? "show" : ""
                      } ${
                        this.props.data.menu === "dashboard" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/dashboard" className="nav-link">
                          <Home color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Dashboard</span>
                        </Link>
                      </span>
                    </li>
                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "users" ? "show" : ""
                      } ${this.props.data.menu === "users" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/users" className="nav-link">
                          <Users color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Manage Users</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "client" ? "show" : ""
                      } ${this.props.data.menu === "client" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/clients" className="nav-link">
                          <Slack color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Clients</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "staff" ? "show" : ""
                      } ${this.props.data.menu === "staff" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/staffs" className="nav-link">
                          <User color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Staffs</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "facility" ? "show" : ""
                      } ${this.props.data.menu === "facility" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/facilities" className="nav-link">
                          <Cpu color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">
                            Manage Facilities
                          </span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "credentials" ? "show" : ""
                      } ${
                        this.props.data.menu === "credentials" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/credentials" className="nav-link">
                          <FileText color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Credentials</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "documents" ? "show" : ""
                      } ${
                        this.props.data.menu === "documents" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/documents" className="nav-link">
                          <File color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Documents</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "quiz" ? "show" : ""
                      } ${this.props.data.menu === "quiz" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/quiz" className="nav-link">
                          <HelpCircle color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Manage Quiz</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "payment information"
                          ? "show"
                          : ""
                      } ${
                        this.props.data.menu === "payment information"
                          ? "active"
                          : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/payment-info" className="nav-link">
                          <Cast color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">
                            Payment Information
                          </span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "category" ? "show" : ""
                      } ${this.props.data.menu === "category" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/job-category" className="nav-link">
                          <GitBranch color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Job Category</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "ca" ? "show" : ""
                      } ${this.props.data.menu === "ca" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/job-ca" className="nav-link">
                          <AtSign color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Job Names</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "license" ? "show" : ""
                      } ${this.props.data.menu === "license" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/license" className="nav-link">
                          <Award color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">License Type</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "jobs" ? "show" : ""
                      } ${this.props.data.menu === "jobs" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/jobs" className="nav-link">
                          <Briefcase color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Jobs</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "withdraws" ? "show" : ""
                      } ${
                        this.props.data.menu === "withdraws" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/withdrawals" className="nav-link">
                          <DollarSign color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">
                            Manage Withdrawals
                          </span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "notification"
                          ? "show"
                          : ""
                      } ${
                        this.props.data.menu === "notification" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/notifications" className="nav-link">
                          <Bell color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Notifications</span>
                        </Link>
                      </span>
                    </li>
                  </>
                )}

                {userDetails.type == 2 && (
                  <>
                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "dashboard" ? "show" : ""
                      } ${
                        this.props.data.menu === "dashboard" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/dashboard" className="nav-link">
                          <Home color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Dashboard</span>
                        </Link>
                      </span>
                    </li>
                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "jobs" ? "show" : ""
                      } ${this.props.data.menu === "jobs" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/jobs" className="nav-link">
                          <Briefcase color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Jobs</span>
                        </Link>
                      </span>
                    </li>
                  </>
                )}

                {userDetails.type == 3 && (
                  <>
                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "dashboard" ? "show" : ""
                      } ${
                        this.props.data.menu === "dashboard" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/dashboard" className="nav-link">
                          <Home color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Dashboard</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "facility" ? "show" : ""
                      } ${this.props.data.menu === "facility" ? "active" : ""}`}
                    >
                      <span className="st-link">
                        <Link to="/staff-facilities" className="nav-link">
                          <Cpu color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Staff Facilities</span>
                        </Link>
                      </span>
                    </li>

                    <li
                      className={`nav-item st-link-hover st-nav-pad ${
                        this.props.data.children === "notification"
                          ? "show"
                          : ""
                      } ${
                        this.props.data.menu === "notification" ? "active" : ""
                      }`}
                    >
                      <span className="st-link">
                        <Link to="/notifications" className="nav-link">
                          <Bell color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                          <span className="st-link-span">Notifications</span>
                        </Link>
                      </span>
                    </li>
                  </>
                )}

                <li
                  onClick={() => this.OpenMenu("", "")}
                  className={`nav-item st-link-hover st-nav-pad ${
                    this.props.data.children === "Students" ? "show" : ""
                  } ${this.props.data.menu === "Students" ? "active" : ""}`}
                >
                  <span className="st-link">
                    <a href="/logout" className="nav-link">
                      <LogOut color="rgba(27, 46, 75, 0.9)" size={48} />{" "}
                      <span className="st-link-span">Log out</span>
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </aside>
        </FadeIn>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(LeftNav);
