import React, { Component } from "react";
import { connect } from "react-redux";
import { open_menu } from "../store/actions/OpenMenu";
import { open_main_menu } from "../store/actions/MainMenu";
import { change_breadcrum } from "../store/actions/Bredcrum";
import { BounceRight, FadeIn, BounceLeft } from "animate-components";
import {
  HardDrive,
  Package,
  Users,
  Database,
  DollarSign,
  GitBranch,
  Briefcase,
  Framer,
  X,
  XCircle,
  ThumbsDown,
  Calendar,
  Delete,
  CheckCircle,
  Layers,
} from "react-feather";
import { formatAmount, Home } from "../global/Home";
import Axios from "axios";
import Spinner from "../global/Spinner";
import moment from "moment";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6],
      data: "",
      modal: "",
      show: "",
      info: "",
      title: "",
      loading: false,
    };
  }

  SampleNavigator = (name) => {
    this.props.history.push(`/${name}`);
  };

  LoadData = (filter) => {
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.get(`${Home}admin/dash`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res);
          this.setState({ loading: false, data: res.data.payload });
        })
        .catch((err) => console.log(err));
    }
  };

  componentDidMount() {
    this.LoadData();
    this.props.dispatch(open_menu("dashboard"));
    this.props.dispatch(open_main_menu("dashboard"));
    this.props.dispatch(change_breadcrum("Your Dashboard"));
  }

  OpenModal2 = (modal, id, da) => {
    this.setState({ info: da });
    if (modal.length < 2) {
      this.setState({ show: "" });
      this.interval = setTimeout(() => this.setState({ modal }), 600);
    } else {
      this.setState({ modal });
      this.interval = setTimeout(() => this.setState({ show: "show" }), 100);
    }
    this.setState({ id });
  };

  render() {
    {
      this.state.data.deleted === 1 && localStorage.clear("userToken");
    }

    // console.log(this.props.data.userDetails);
    let userDetails = this.props.data.userDetails;
    return (
      <>
        {this.state.modal === "OPEN" && (
          <div
            className={`modal effect-super-scaled ${this.state.show} `}
            id="exampleModalCenter"
            role="dialog"
            style={{
              display: "block",
              background: this.state.show === "" ? "none" : "#050404d4",
              overflow: "scroll",
            }}
          >
            <div className="modal-dialog modal-lg" role="document">
              <form
                onSubmit={this.handleSubmit}
                className="modal-content card explore-feature border-0 rounded bg-white shadow"
              >
                {/* <div className="modal-header">
                  <h5 className="modal-title">{this.state.title}</h5>
                  <button
                    onClick={() => this.OpenModal2("", 0, "")}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div> */}
                <div className="modal-body">
                  <div>
                    <h5>{this.state.info.name}</h5>
                    <span className="text-muted font-weight-bold">
                      {this.state.info.created_at
                        ? this.state.info.created_at.slice(0, 10)
                        : ""}
                    </span>
                    <div className="pt-1">
                      <p>{this.state.info.draft}</p>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                  >
                    Submit
                  </button> */}
                  <button
                    onClick={() => this.OpenModal2("", 0, "")}
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        <div>
          <FadeIn duration="1s" timingFunction="ease-out">
            {userDetails.type == 1 && (
              <div className="row no-gutters">
                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/users")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#7fb5ff" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Users</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Users color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        <h2 className="text-right pt-2 pl-1 text-white">
                          {this.state.data.countUser}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/jobs")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#413e56" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Jobs</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Briefcase color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data.countJobs}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/job-category")}
                    className="card card-custom shadow-none border-0 gutter-b pointer bg-danger"
                    style={{ background: "#e01515" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Categories</h4>
                      </div>
                    </div>

                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <GitBranch color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data.countCategory}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/withdrawals")}
                    className="card card-custom shadow-none border-0 gutter-b pointer bg-warning"
                    style={{ background: "#f35656" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">
                          Withdraw Request
                        </h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <DollarSign color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}
                      <h2 className="text-right text-white pt-2 pl-1">
                        {this.state.data.countWithdrawRequest}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-purple"
                    style={{ background: "#0c3a59" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">
                          Job Applications
                        </h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Calendar color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        <h2 className="text-right pt-2 pl-1 text-white">
                          {this.state.data?.CUserJob?.length}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-secondary"
                    style={{ background: "#e01515" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Canceled Jobs</h4>
                      </div>
                    </div>

                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Delete color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data?.CUserJobCancel?.length}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-dark"
                    style={{ background: "#f35656" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Signed Jobs</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <CheckCircle color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}
                      <h2 className="text-right text-white pt-2 pl-1">
                        {this.state.data?.CUserJobSigned?.length}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/facilities")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#7fb5ff" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Facilities</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Layers color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}
                      <h2 className="text-right text-white pt-2 pl-1">
                        {this.state.data?.Facility}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails.type == 2 && (
              <div className="row no-gutters">
                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#413e56" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Total Jobs</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Briefcase color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data?.Cjob?.length}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b"
                    style={{ background: "#7fb5ff" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">
                          Job Applications
                        </h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Users color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        <h2 className="text-right pt-2 pl-1 text-white">
                          {this.state.data?.CUserJob?.length}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-danger"
                    style={{ background: "#e01515" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Canceled Jobs</h4>
                      </div>
                    </div>

                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <ThumbsDown color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data?.CUserJobCancel?.length}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-warning"
                    style={{ background: "#f35656" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Signed Jobs</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Framer color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}
                      <h2 className="text-right text-white pt-2 pl-1">
                        {this.state.data?.CUserJobSigned?.length}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails.type == 3 && (
              <div className="row no-gutters">
                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/users")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#7fb5ff" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Users</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Users color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        <h2 className="text-right pt-2 pl-1 text-white">
                          {this.state.data.countUser}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/jobs")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#413e56" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Jobs</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Briefcase color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}{" "}
                      <h2 className="text-right pt-2 pl-1 text-white">
                        {this.state.data.countJobs}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    className="card card-custom shadow-none border-0 gutter-b bg-purple"
                    style={{ background: "#0c3a59" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">
                          Job Applications
                        </h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Calendar color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        <h2 className="text-right pt-2 pl-1 text-white">
                          {this.state.data?.CUserJob?.length}
                        </h2>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-3 pr-3 mb-3">
                  <div
                    onClick={() => this.props.history.push("/facilities")}
                    className="card card-custom shadow-none border-0 gutter-b pointer"
                    style={{ background: "#7fb5ff" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="d-inline text-white">Facilities</h4>
                      </div>
                    </div>
                    <div
                      id="kt_stats_widget_11_chart"
                      className="d-flex align-self-end"
                      data-color="success"
                      style={{
                        height: "100px",
                        minHeight: "100px",
                        // overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      <Layers color="white" size={48} />
                      {this.state.loading ? (
                        <Spinner size={40} color="#ffffff" />
                      ) : (
                        ""
                      )}
                      <h2 className="text-right text-white pt-2 pl-1">
                        {this.state.data?.Facility}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails.type == 1 && (
              <div className="row no-gutters mt-2">
                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Recent Jobs</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentJob &&
                      this.state.data.recentJob.length > 0 ? (
                        <div>
                          {this.state.data.recentJob.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{
                                  padding: "10px 10px 10px 15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // this.OpenModal2("OPEN", 0, da);
                                }}
                              >
                                <div>
                                  <h5>{da.title}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.start_date).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      <strong>Location: </strong>
                                      {da.location}
                                    </p>
                                  </div>
                                  <div className="pt-">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Job Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Withdraw Request</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentWith &&
                      this.state.data.recentWith.length > 0 ? (
                        <div>
                          {this.state.data.recentWith.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{ padding: "10px 10px 10px 15px" }}
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                              >
                                <div>
                                  <h5>{formatAmount(da.amount)}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>{da.user?.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Withdraw Request Yet</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Credentials</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentCred &&
                      this.state.data.recentCred.length > 0 ? (
                        <div>
                          {this.state.data.recentCred.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                                style={{ padding: "10px 10px 10px 15px" }}
                              >
                                <div>
                                  <h5>{da.name}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Credentials Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 py-3 pr-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Documents</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentDoc &&
                      this.state.data.recentDoc.length > 0 ? (
                        <div>
                          {this.state.data.recentDoc.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                                style={{ padding: "10px 10px 10px 15px" }}
                              >
                                <div>
                                  <h5>{da.name}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Documents Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 py-3 pr-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Account Info</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentPay &&
                      this.state.data.recentPay.length > 0 ? (
                        <div>
                          {this.state.data.recentPay.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                                style={{ padding: "10px 10px 10px 15px" }}
                              >
                                <div>
                                  {/* <h5>{da.name}</h5> */}
                                  <div className="pt-1">
                                    <span>
                                      <strong>Account Name:</strong> {da.type}
                                    </span>
                                  </div>
                                  <div className="pt-1">
                                    <span>
                                      <strong>Account Number:</strong>{" "}
                                      {da.number}
                                    </span>
                                  </div>
                                  <div className="pt-1">
                                    <span>
                                      <strong>Account Type:</strong> {da.type}
                                    </span>
                                  </div>
                                  <div className="pt-1">
                                    <span>
                                      <strong>Route:</strong> {da.route}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Documents Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails.type == 2 && (
              <div className="row no-gutters mt-2">
                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Recent Jobs</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.CRecentJob &&
                      this.state.data.CRecentJob.length > 0 ? (
                        <div>
                          {this.state.data.CRecentJob.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{
                                  padding: "10px 10px 10px 15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // this.OpenModal2("OPEN", 0, da);
                                }}
                              >
                                <div>
                                  <h5>{da.title}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.start_date).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      <strong>Location: </strong>
                                      {da.location}
                                    </p>
                                  </div>
                                  <div className="pt-">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Job Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Recent Canceled Jobs</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.RecentJobCancel &&
                      this.state.data.RecentJobCancel.length > 0 ? (
                        <div>
                          {this.state.data.RecentJobCancel.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{ padding: "10px 10px 10px 15px" }}
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                              >
                                <div>
                                  <h5>{formatAmount(da.amount)}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>{da.user?.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Data Yet</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Recent Signed Jobs</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.RecentJobSigned &&
                      this.state.data.RecentJobSigned.length > 0 ? (
                        <div>
                          {this.state.data.RecentJobSigned.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{
                                  padding: "10px 10px 10px 15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // this.OpenModal2("OPEN", 0, da);
                                }}
                              >
                                <div>
                                  <h5>{da.title}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.start_date).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      <strong>Location: </strong>
                                      {da.location}
                                    </p>
                                  </div>
                                  <div className="pt-">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Data Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {userDetails.type == 3 && (
              <div className="row no-gutters mt-2">
                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Recent Jobs</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentJob &&
                      this.state.data.recentJob.length > 0 ? (
                        <div>
                          {this.state.data.recentJob.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{
                                  padding: "10px 10px 10px 15px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  // this.OpenModal2("OPEN", 0, da);
                                }}
                              >
                                <div>
                                  <h5>{da.title}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.start_date).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      <strong>Location: </strong>
                                      {da.location}
                                    </p>
                                  </div>
                                  <div className="pt-">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Job Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Withdraw Request</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentWith &&
                      this.state.data.recentWith.length > 0 ? (
                        <div>
                          {this.state.data.recentWith.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                style={{ padding: "10px 10px 10px 15px" }}
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                              >
                                <div>
                                  <h5>{formatAmount(da.amount)}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>{da.user?.name}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Withdraw Request Yet</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-4 pr-3 mb-3">
                  <div className="card card-custom card-stretch gutter-b shadow-none border-0 st-home-c">
                    <div
                      className="card-header  "
                      style={{ background: "#fff" }}
                    >
                      <div className="card-title">
                        <h4 className="colo-b">Credentials</h4>
                      </div>
                    </div>
                    <div
                      className="hideScroll"
                      style={{
                        height: "52.5vh",
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                      }}
                    >
                      {this.state.data.recentCred &&
                      this.state.data.recentCred.length > 0 ? (
                        <div>
                          {this.state.data.recentCred.map((da) => (
                            <>
                              <div
                                key={da._id}
                                className="sms"
                                // onClick={() => this.OpenModal2("OPEN", 0, da)}
                                style={{ padding: "10px 10px 10px 15px" }}
                              >
                                <div>
                                  <h5>{da.name}</h5>
                                  <span className="text-muted font-weight-bold">
                                    {moment(da.created_at).format(
                                      "MMM Do, h:mm:ss A"
                                    )}
                                  </span>
                                  <div className="pt-1">
                                    <p>
                                      {da.desc && da.desc.length > 90
                                        ? da.desc.slice(0, 90)
                                        : da.desc}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom"></div>
                            </>
                          ))}
                        </div>
                      ) : (
                        <div className="p-4 ">No Credentials Yet</div>
                      )}

                      {/* <div className="border-bottom"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FadeIn>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Dashboard);
