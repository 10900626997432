import React, { Component } from "react";
import { connect } from "react-redux";
import AddFacilityJob from "./AddFacilityJob";
import EditFacilityJob from "./EditFacilityJob";
import ListMyFacility from "./ListMyFacility";
import ListFacilityJobs from "./ListFacilityJobs";
import FacilitySubmittedJob from "./FacilitySubmittedJob";

class StaffFacilitiesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      current: "List",
    };
  }

  Switcher = () => {
    let current = this.props.data.switch;
    switch (current) {
      case "edit_job":
        return <EditFacilityJob />;
      case "add_job":
        return <AddFacilityJob />;
      case "list_jobs":
        return <ListFacilityJobs />;
      case "submitted_jobs":
        return <FacilitySubmittedJob />;
      default:
        return <ListMyFacility />;
    }
  };

  render() {
    return <div>{this.Switcher()}</div>;
  }
}

const mapStateToProps = (store) => ({
  data: store,
});

export default connect(mapStateToProps)(StaffFacilitiesContainer);
