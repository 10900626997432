import React, { Component } from "react";
import { connect } from "react-redux";
import { login_layout } from "../store/actions/LoginLayout";
import { Key, Mail } from "react-feather";
import Particles from "react-particles-js";
// import Particles from "react-particles";
import Spinner from "../global/Spinner";
import { toast } from "react-toastify";
import Axios from "axios";
import { Home } from "../global/Home";
import Toaster from "../global/Toaster";
import img from "../assets/img/logotwo.png";
import { BounceUp, FadeIn } from "animate-components";
import { Link } from "react-router-dom";
import ForgottenPass from "../global/ForgottenPass";
import { passwordStrength } from "check-password-strength";

const defaultOptions = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 0,
    minLength: 1,
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 1,
    minLength: 3,
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 3,
    minLength: 4,
  },
];
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      email: "",
      password: "",
      loading: false,
      strenght: "",
    };
  }

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "password") {
        this.setState({
          strenght: passwordStrength(this.state.password, defaultOptions).value,
        });
        if (
          passwordStrength(this.state.password, defaultOptions).value ==
          "Too weak"
        ) {
          this.setState({ css: "text-danger" });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value == "Weak"
        ) {
          this.setState({ css: "text-danger" });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value ==
          "Medium"
        ) {
          this.setState({ css: "text-warning" });
        } else if (
          passwordStrength(this.state.password, defaultOptions).value ==
          "Strong"
        ) {
          this.setState({ css: "text-success" });
        }
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    Axios.post(`${Home}auth/login`, {
      type: 2,
      email: this.state.email,
      password: this.state.password,
      remember_me: "yes",
    })
      .then((res) => {
        console.log(res);
        this.setState({ loading: false });
        let { data } = res;
        if (res.data.success) {
          localStorage.setItem("userToken", JSON.stringify(data.payload.token));
          localStorage.setItem("userData", JSON.stringify(data.payload.user));

          this.props.history.push("/dashboard");
        } else {
          this.ErrorHandler(data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          this.ErrorHandler(data.message);
        } else {
          this.ErrorHandler(err.message);
        }
      });
  };

  componentDidMount() {
    this.props.dispatch(login_layout("STEPHEN_WHOCODED"));
  }

  componentWillUnmount() {
    this.props.dispatch(login_layout(""));
  }
  Switch = (name) => {
    this.setState({ switch: name });
  };
  render() {
    return (
      <>
        {this.state.switch === "WHOCODED" ? (
          <ForgottenPass close={() => this.Switch("")} />
        ) : (
          ""
        )}
        <div className="st-h-100">
          <Toaster />
          {/* <div className="ss-m ssdds">
                    <div className="container sufu">
                    <form onSubmit={this.handleSubmit} className=" ">
                        <h2 className="text-primary mg-b-5 text-center">Sign In</h2>
                        <p className="text-primary22 tx-16 mg-b-40 text-center">on your account</p>
                            <div className="form-group">
                                <label className="text-primary22">Email Address</label>
                                <input 
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.email}
                                autoFocus={true} 
                                type="email" 
                                required
                                className="form-control st-login-f" 
                                id="inlineFormInputGroup" placeholder="Email Address"/>
                            </div>
                            <div className="form-group">
                                <label className="text-primary22">Password</label>
                                <input 
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                type="password"
                                required
                                 className="form-control st-login-f" 
                                 id="inlineFormInputGroup"
                                  placeholder="Email Address"/>
                            </div>
                            <div className="form-group">
                                {this.state.loading ? 
                                <Spinner size={40} />
                            :
                            this.state.password.length > 0 && this.state.email.length > 0 ?
                            <button type="submit" className="btn btn-primary st-btn shadow btn-block"> <strong>Sign In</strong></button>
                            :
                            <button type="button" disabled={true} className="btn btn-primary st-btn shadow btn-block"> <strong>Sign In</strong></button>
                            }
                                
                            </div>
                        </form>
                        
                    </div>
                </div> */}
          <div className="row ss-dd">
            <div className="col-md-6 App-header">
              <form
                onSubmit={this.handleSubmit}
                className="container content content-fixed content-auth-alt login-f "
              >
                <div className="text-primary2 mg-b-5 text-left st-hhd">
                  Login
                </div>
                <p className="text-primary22 tx-16 mg-b-40 text-left">
                  Login to your to manage your account
                </p>
                <div className="form-group">
                  <label className="text-primary22 st-label">
                    EMAIL ADDRESS
                  </label>
                  <input
                    onChange={this.handleChange}
                    name="email"
                    value={this.state.email}
                    autoFocus={true}
                    type="email"
                    required
                    className="form-control st-login-f"
                    id="inlineFormInputGroup"
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label className="text-primary22 st-label">Password</label>
                  <input
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    type="password"
                    required
                    className="form-control st-login-f"
                    id="inlineFormInputGroup"
                    placeholder=""
                  />
                  <small
                    style={{ fontSize: "14px" }}
                    className={`${this.state.css}`}
                  >
                    {this.state.strenght}
                  </small>
                </div>
                <div
                  className="text-primary22 tx-13 mg-t0 mb-3 tx-center text-right"
                  style={{ fontWeight: "900" }}
                >
                  {/* <Link onClick={()=>this.Switch('WHOCODED')} className="text-primary2" to="#">Forgotten Password?</Link> */}
                </div>
                <div className="form-group">
                  {this.state.loading ? (
                    <Spinner size={55} />
                  ) : this.state.password.length > 0 &&
                    this.state.email.length > 0 ? (
                    <button
                      type="submit"
                      className="btn btn-primary st-btn shadow btn-block btn-sm"
                    >
                      {" "}
                      LOGIN
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={false}
                      className="btn btn-primary st-btn btn-sm shadow btn-block"
                    >
                      {" "}
                      LOGIN
                    </button>
                  )}
                </div>

                {/* <div className="text-primary22 tx-13 mg-t-20 tx-center" style={{fontWeight:'900'}}>Don't have an account? <Link to="/register">Create an account</Link></div> */}
              </form>
            </div>
            <div className="col-md-6 bg--b st-h-100 ">
              <div className="bgbg"></div>
              <div className="ss-d">
                <Particles
                  params={{
                    particles: {
                      number: {
                        value: 200,
                        density: {
                          enable: false,
                        },
                      },
                      size: {
                        value: 4,
                        random: true,
                        anim: {
                          speed: 4,
                          size_min: 0.3,
                        },
                      },
                      line_linked: {
                        enable: false,
                      },
                      move: {
                        random: true,
                        speed: 1,
                        direction: "top",
                        out_mode: "out",
                      },
                    },
                    interactivity: {
                      events: {
                        onhover: {
                          enable: true,
                          mode: "bubble",
                        },
                        onclick: {
                          enable: true,
                          mode: "repulse",
                        },
                      },
                      modes: {
                        bubble: {
                          distance: 250,
                          duration: 2,
                          size: 0,
                          opacity: 0,
                        },
                        repulse: {
                          distance: 400,
                          duration: 4,
                        },
                      },
                    },
                  }}
                />
              </div>
              <BounceUp duration="4s" timingFunction="ease-out">
                <div className="img-container-block ss-d">
                  <img
                    style={{ width: "200px" }}
                    className="img-container-inline"
                    src={img}
                  />
                  {/* <span className="whocoded-glow2"></span> */}
                </div>
              </BounceUp>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(Login);
