import Axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { Component } from "react";
import { Home } from "../../../global/Home";
import { ArrowLeft } from "react-feather";
import Spinner from "../../../global/Spinner";
import "react-datepicker/dist/react-datepicker.css";
import { BounceRight, FadeIn } from "animate-components";
import { launch_toaster } from "../../../store/actions/IsToast";
import { props_params } from "../../../store/actions/PropsParams";
import { toast_trigger } from "../../../store/actions/ToastTrigger";
import { switch_content } from "../../../store/actions/SwitchContent";

class AddStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "",
      name: "",
      loading: false,
      data: [],
      department: "",
      level: "",
      details: [],
      lg: [],
      lgs: "",
      state: "",
      country: "",
      address: "",
      startDate: new Date(),
      startDate2: new Date(),
      show: "",
      note: "",
      number: "",
      data2: [],
      markup: "",
      premium: "",
      net_premium: "",
      commission: "",
      company: "",
      dataf: [],
      seleted: [],
      steps: "",
      value: "",
      location: "",
    };
    this.fileInput = React.createRef();
  }

  ErrorHandler = (message) => {
    //console.clear();
    console.log(message);
    this.setState({ loading: false });
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  handleChange = (event) => {
    if (event.target.type !== "files") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.name === "state") {
        if (this.state.details[event.target.value].lgas) {
          this.setState({ lg: this.state.details[event.target.value].lgas });
        }
      }
    }
  };

  SwitchContent = (name, id) => {
    this.props.dispatch(switch_content(name));
    this.props.dispatch(props_params(id));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let token = "";
    if (localStorage.getItem("userToken")) {
      token = JSON.parse(localStorage.getItem("userToken"));
      this.setState({ loading: true });
      Axios.post(
        `${Home}admin/staff/create`,
        {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          password: this.state.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          // console.log(res);
          this.setState({ loading: false });
          if (res.data.success) {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(true));
            this.SwitchContent("", [0]);
          } else {
            this.props.dispatch(launch_toaster(res.data.message));
            this.props.dispatch(toast_trigger(false));
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.response) {
            const { data } = err.response;
            this.props.dispatch(launch_toaster(data.message));
            this.props.dispatch(toast_trigger(false));
          } else {
            this.props.dispatch(launch_toaster(err.message));
            this.props.dispatch(toast_trigger(false));
          }
        });
    }
  };

  render() {
    return (
      <>
        <BounceRight duration="1s" timingFunction="ease-out">
          <div className="card border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-5">
                  <h6 class="lh-5 mg-b-0">Add Staff</h6>
                </div>
                <div className="col-md-7">
                  <div className="pull-right">
                    <FadeIn duration="1s" timingFunction="ease-out">
                      <button
                        onClick={() => this.SwitchContent("dep_home", [0])}
                        className="btn btn-danger shadow"
                      >
                        <ArrowLeft color="white" size={35} /> Return
                      </button>
                    </FadeIn>
                  </div>
                </div>
              </div>
              <form onSubmit={this.handleSubmit} className="mt-4 row">
                <div className="col-md-6">
                  <div className="form-group mt-5">
                    <label>First Name</label>
                    <input
                      value={this.state.first_name}
                      name="first_name"
                      required
                      placeholder="First Name"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mt-5">
                    <label>Last Name</label>
                    <input
                      value={this.state.last_name}
                      name="last_name"
                      required
                      placeholder="Last Name"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      value={this.state.email}
                      name="email"
                      type="email"
                      placeholder="Eg. facilti@host.com"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      value={this.state.password}
                      name="password"
                      type="password"
                      placeholder="xxxx-xxxx"
                      className="form-control st-login-f22"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-12" style={{ marginTop: "1rem" }}>
                  <div className="form-group">
                    {this.state.loading ? (
                      <Spinner size={40} />
                    ) : (
                      <button className="btn st-btn2 btn-primary shadow">
                        Save Now
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </BounceRight>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    data: store,
  };
};

export default connect(mapStoreToProps)(AddStaff);
